import { Button, Divider, Grid, InputBase, Pill, Text, TextInput, Title } from '@mantine/core'
import { useForm } from '@mantine/form';
import { useContext, useEffect, useMemo, useState } from 'react';
import { BaseResult, UpsertCampaignRequest, Customer, FundID, FundAutoComplete, Campaign, Fund } from '../../types';
import { CustomerSelectComponent } from '../../components/customer_select_component';
import { CustomerTagSelectComponent } from '../../components/customer_tag_select_component';
import { httpsCallable } from 'firebase/functions';
import { MessagesSent } from '../../components/send_sms_modal';
import { useFirestore, useFunctions } from '../../helpers/firebaseContext';
import { useNavigate, useParams } from 'react-router-dom';
import BasicCampaignForm from './basic_campaign_form';
import {
    collection, doc, getDoc, limit, onSnapshot, query,
    where,
    // where
} from 'firebase/firestore';
import { GlobalDataContext } from '../../contexts';
const CreateCampaign = () => {
    const [chosenCustomersList, setChosenCustomersList] = useState<Partial<Customer>[]>([])
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [SMSDocID,] = useState<string[]>();
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
    const [errMsg, setErrMsg] = useState<string>();
    const [campaign, setCampaign] = useState<UpsertCampaignRequest>();
    const [chosenFund, setChosenFund] = useState<Fund>();

    // const [funds, setFunds] = useState<FundAutoComplete[]>([]);
    const functions = useFunctions();
    const navigate = useNavigate();
    const db = useFirestore();

    const { campaignId } = useParams();

    const globalDataContext = useContext(GlobalDataContext);

    const funds = globalDataContext?.funds?.map((fundObj) => { return { ...fundObj, label: fundObj.name_en, value: fundObj.id } }) || []

    const form = useForm<
        UpsertCampaignRequest
    >({
        initialValues: {
            title: "",
            rmRecipients: [],
            segmentsRecipients: [],
            customerRecipients: [],
            tagsRecipients: [],
            smsMessage: "",
            type: [],
            updateLink: {
                updateType: "broadcast",
            },

            messageVariables: {},
        },

    });


    useEffect(() => {
        form.setFieldValue("customerRecipients", chosenCustomersList?.map((customer) => customer.id!))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chosenCustomersList])

    //get funds  and campaign data
    useEffect(() => {



        const getCampaign = async () => {
            let campaignRef = doc(db, "campaigns", campaignId!);

            const campaignDoc = await getDoc(campaignRef);



            if (campaignDoc.exists()) {
                const campaignData = campaignDoc.data() as Campaign;
                setCampaign({
                    ...campaignData,
                    updateLink: {
                        updateType: "broadcast",
                    },

                });
                if (campaignData?.customer_ids?.length) {

                    setChosenCustomersList(campaignData.customer_ids.map(((customerID) => {
                        return {
                            id: customerID,

                        }
                    })));
                }
                const fundTags = funds.filter((fundObj) => campaignData.tagsRecipients.includes(fundObj.id))

                if (fundTags?.length === 1)
                    setChosenFund(fundTags[0])
            }


        }
        if (campaignId) {

            getCampaign();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaignId]);



    useEffect(() => {


        form.setValues({
            ...campaign,
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaign])


    const renderBasicCampaignForm = useMemo(() =>
        <>
            <BasicCampaignForm form={form} funds={chosenFund ? [{
                ...chosenFund,
                id: chosenFund.id,
                label: `${chosenFund.name_en} - ${chosenFund.name_ar}`,
                value: chosenFund.id,
            }] : funds?.map((fundObj) => {

                return {
                    ...fundObj,
                    id: fundObj.id,
                    label: `${fundObj.name_en} - ${fundObj.name_ar}`,
                    value: fundObj.id,
                }
            })} />

        </>
        , [form, funds, chosenFund])


    return (
        <Grid justify='center' bg={"white"} h="100%">
            <Grid.Col span={{ xs: 12 }}>


                <Title
                    ms={30}
                    mt={30}
                    mb={20}
                    order={4}
                    ta="left">{campaignId ? "Edit" : "Create"} Campaign</Title>
            </Grid.Col>
            {/* <LoadingOverlay visible={visible} loaderProps={{ children: 'Loading...' }} /> */}

            <Grid.Col span={{ lg: 8 }}>

                <form
                    onSubmit={form.onSubmit(async (values) => {
                        setErrMsg(undefined)

                        if (values.customerRecipients.length <= 0 && values.tagsRecipients.length <= 0) {
                            setErrMsg("either customers or tags need to be added");
                            return;
                        }
                        if (values.type.length <= 0) {
                            setErrMsg("atleast one type must be selected");
                            return;
                        }


                        setLoadingSubmit(true)
                        try {


                            if (campaignId) {

                                const EditCampaign = httpsCallable<UpsertCampaignRequest, BaseResult>(functions, "editCampaign");


                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                const response = await EditCampaign({
                                    ...campaign,
                                    ...values
                                })
                            }
                            else {

                                const createCampaign = httpsCallable<UpsertCampaignRequest, BaseResult>(functions, "createCampaign");
                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                const response = await createCampaign(values)
                            }
                            setLoadingSubmit(false);
                            navigate("/campaigns");
                            return;

                            // if (response?.data?.messageID) {

                            //     setSMSDocID([response?.data?.messageID]);
                            //     setLoadingSubmit(false);


                            // }
                            // if (response?.data?.messageIDsList) {
                            //     setSMSDocID(response?.data?.messageIDsList);
                            //     setLoadingSubmit(false);

                            // }


                        }
                        catch (e: any) {
                            if (e?.message) {
                                setErrMsg(e?.message)

                            }
                            else {
                                setErrMsg("an unexpcted error has occured ")

                            }

                            setLoadingSubmit(false);

                        }

                    })}
                >
                    <Grid>

                        <Grid.Col span={{ xs: 12 }}>
                            <TextInput
                                label="Title"
                                required
                                // placeholder=''
                                // mt={10}
                                {...form.getInputProps('title')}
                                value={form.values.title}
                                error={form.errors.title}
                                onChange={(e) => {
                                    form.setFieldValue("title", e.target.value)
                                }}
                            />
                        </Grid.Col>

                        <Grid.Col span={{ lg: 4 }}>
                            <CustomerSelectComponent
                                textProps={{
                                    label: "Search Customers by (ID / Name / Tag)",
                                    required: false
                                }}

                                submitType="object"
                                handleSubmit={(customerUserObj) => {
                                    let customerUser = customerUserObj as Customer
                                    if (customerUser) {
                                        const foundCustomer = chosenCustomersList.find((chosenCustomer) => chosenCustomer.id === customerUser.id)
                                        if (!foundCustomer) {
                                            setChosenCustomersList([...chosenCustomersList, customerUser])
                                        }
                                    }
                                }}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ lg: 8 }}>
                            <InputBase component="div" multiline
                                placeholder='Chosen Customers'
                                label="Chosen Customers"
                            >
                                <Pill.Group
                                    placeholder='Chosen Customers'
                                >{chosenCustomersList.map((chosenCustomer) => <Pill
                                    withRemoveButton
                                    onRemove={() => {
                                        const updatedCustomersList = chosenCustomersList.filter((customerUser) =>
                                            customerUser.id !== chosenCustomer.id
                                        )
                                        setChosenCustomersList(updatedCustomersList)
                                    }}
                                >
                                    {chosenCustomer?.name_en} -  {chosenCustomer?.id}
                                </Pill>)}
                                </Pill.Group>
                            </InputBase>
                        </Grid.Col>
                        <Divider my="md" w="100%" />

                        <Grid.Col span={{ xs: 12 }}>

                            <CustomerTagSelectComponent
                                textProps={{
                                    label: "Tags",
                                    required: false
                                }}
                                // fundsList={funds}
                                tagsList={form.values.tagsRecipients}

                                onChange={(value) => {
                                    form.setFieldValue("tagsRecipients", value)
                                }}
                            />
                        </Grid.Col>
                        <Divider my="md" w="100%" />
                        <Grid.Col span={{ xs: 12 }}>
                            {renderBasicCampaignForm}
                        </Grid.Col>

                        <Grid.Col span={{ xs: 12 }} >
                            {
                                SMSDocID?.map((smsDoc) => <MessagesSent docID={smsDoc} />)
                            }
                        </Grid.Col>
                        <Grid.Col span={{ xs: 12 }} >
                            <Text c={"red"}>
                                {errMsg}
                            </Text>
                        </Grid.Col>

                        <Grid.Col span={{ xs: 3 }} >
                            <Button
                                loading={loadingSubmit}
                                color='green'
                                type='submit'
                                fullWidth

                            >
                                {campaignId ? "Edit" : "Create"}
                            </Button>
                        </Grid.Col>
                    </Grid>
                </form>
            </Grid.Col>

        </Grid >
    )
}

export default CreateCampaign