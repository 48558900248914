import React, { useContext } from 'react';
import { Tooltip, Badge, NumberFormatter } from '@mantine/core';
import { ConfigContext, GlobalDataContext } from '../contexts';
import { Customer, CustomerFundData, Portfolio } from '../types';

interface PositionBadgesProps {
    portfolioData: Portfolio[];
    fundData: CustomerFundData[];
}

const PositionBadges: React.FC<PositionBadgesProps> = ({ portfolioData, fundData }) => {

    //use context data
    const configContext = useContext(ConfigContext);
    const globalDataContext = useContext(GlobalDataContext);

    // Calculate portfoliosTotalValue
    let portfoliosTotalValue = -1;
    if (portfolioData && portfolioData.length > 0) {
        const clientHavePortfolios = portfolioData.filter((portfolio) => portfolio.portfolioTypeID != 145).length > 0;
        if (clientHavePortfolios) {
            portfoliosTotalValue = 0;
            portfolioData.forEach(portfolio => {
                portfoliosTotalValue += portfolio.position?.total_capital_market_value ?? 0;
            });
            portfoliosTotalValue = Math.round(portfoliosTotalValue);
        }
    }

    // Calculate fundsTotalValue
    let fundsTotalValue = -1;
    if (fundData && fundData.length > 0) {
        fundsTotalValue = 0;
        fundData.forEach((holding) => {
            let fund = globalDataContext.funds?.find(obj => obj.code === holding.gl_code);
            const isOpenEnded = fund?.type == 'open_ended';
            const currency = holding.currency ?? 'KWD';
            let total = isOpenEnded ? (holding.eunits ?? 0) * (fund?.nav_per_unit ?? 0) : (holding.total_subscriptions ?? 0);
                if (currency === "KWD") {
                    fundsTotalValue += total;
                } else {
                    let rate = parseFloat(configContext.exchange_rates?.rates[currency] ?? 0);
                    fundsTotalValue += (total * rate);
                }
        });
        fundsTotalValue = Math.round(fundsTotalValue);
    }

    return (
        <>
            {fundsTotalValue >= 0 && (
                <Tooltip label="Funds">
                    <Badge color="pink" size="xs" rightSection="KWD" mr={5}>
                        <NumberFormatter value={fundsTotalValue} thousandSeparator="," />
                    </Badge>
                </Tooltip>
            )}
            {portfoliosTotalValue >= 0 && (
                <Tooltip label="Portfolios">
                    <Badge color="purple" size="xs" rightSection="KWD" mr={5}>
                        <NumberFormatter value={portfoliosTotalValue} thousandSeparator="," />
                    </Badge>
                </Tooltip>
            )}
        </>
    );
};

export default PositionBadges;
