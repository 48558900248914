
//make a component that creates a badge with the given text and color

import { Badge, BadgeProps, Tooltip } from "@mantine/core"
import { Tag } from "../types";

interface TagBadgeProps extends BadgeProps {
    tag?: Tag;
}

export const TagBadge = ({ tag, ...props }: TagBadgeProps) => {
    // style={{ backgroundColor: tag.bgColor, color: tag.color }}

    if (!tag) return null;
    // console.log("Draing real tag!", tag.code);

    return <Tooltip label={tag.title}>
        <Badge {...props} size="xs" variant="filled" color={tag.badgeColor || "gray"}  >{tag.shortName || tag.code?.replaceAll("_", " ")}</Badge>

    </Tooltip>

}
