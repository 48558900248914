import { Table } from "@mantine/core";
import NumberDisplay from "./NumberDisplay";
import { Fund } from "../types";

// Helper function to map day number to day name
const getDayName = (dayOfWeek: number | undefined): string => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return dayOfWeek !== undefined ? days[dayOfWeek] : '-';
};

interface FundSummarizedDataProps {
    fund: Fund;
}

export const FundSummarizedData: React.FC<FundSummarizedDataProps> = ({ fund }) => {

    if (!fund) {
        return null;
    }
    return (
        <>
            <p style={{ textAlign: 'center', margin: 'auto' }}><strong>Fund Facts</strong></p>
            <hr />
            <Table withTableBorder={false} withColumnBorders={false} withRowBorders={false}>
                <Table.Tbody>
                    {fund.currency !== undefined && (
                        <Table.Tr>
                            <Table.Td><strong>Currency</strong></Table.Td>
                            <Table.Td>{fund.currency}</Table.Td>
                        </Table.Tr>
                    )}
                    {fund.nav_per_unit !== undefined && fund.type === 'open_ended' && (
                        <Table.Tr>
                            <Table.Td><strong>NAV per Unit</strong></Table.Td>
                            <Table.Td><NumberDisplay value={fund.nav_per_unit} withCommas customColor='white' /></Table.Td>
                        </Table.Tr>
                    )}
                    {fund.nav_update_day_of_week && fund.type === 'open_ended' !== undefined && (
                        <Table.Tr>
                            <Table.Td><strong>NAV Update Day</strong></Table.Td>
                            <Table.Td>{getDayName(fund.nav_update_day_of_week)}</Table.Td>
                        </Table.Tr>
                    )}
                    {fund.nav_date && fund.type === 'open_ended' !== undefined && (
                        <Table.Tr>
                            <Table.Td><strong>NAV Date</strong></Table.Td>
                            <Table.Td>{fund.nav_date?.toDate().toLocaleDateString("en-gb") ?? '-'}</Table.Td>
                        </Table.Tr>
                    )}
                    {fund.min_units && fund.type === 'open_ended' !== undefined && (
                        <Table.Tr>
                            <Table.Td><strong>Minimum Units</strong></Table.Td>
                            <Table.Td><NumberDisplay value={fund.min_units} withCommas customColor='white' /></Table.Td>
                        </Table.Tr>
                    )}
                    {fund.multiples_of_units && fund.type === 'open_ended' !== undefined && (
                        <Table.Tr>
                            <Table.Td><strong>Multiples of Units</strong></Table.Td>
                            <Table.Td><NumberDisplay value={fund.multiples_of_units} withCommas customColor='white' /></Table.Td>
                        </Table.Tr>
                    )}
                    <Table.Tr>
                        <Table.Td><strong>Fund Type</strong></Table.Td>
                        <Table.Td>{fund.type === 'open_ended' ? 'Open-Ended' : 'Closed-Ended'}</Table.Td>
                    </Table.Tr>
                    {fund.issued_units !== undefined && fund.type === 'open_ended' && (
                        <Table.Tr>
                            <Table.Td><strong>Issued Units</strong></Table.Td>
                            <Table.Td><NumberDisplay value={fund.issued_units} withCommas customColor='white' /></Table.Td>
                        </Table.Tr>
                    )}
                    {fund.net_assets !== undefined && fund.type === 'open_ended' && (
                        <Table.Tr>
                            <Table.Td><strong>Net Assets</strong></Table.Td>
                            <Table.Td><NumberDisplay value={fund.net_assets} withCommas customColor='white' /></Table.Td>
                        </Table.Tr>
                    )}
                    {fund.returns_since_inception && fund.type === 'open_ended' && (
                        <Table.Tr>
                            <Table.Td><strong>Returns Since Inception</strong></Table.Td>
                            <Table.Td>{fund.returns_since_inception}</Table.Td>
                        </Table.Tr>
                    )}
                </Table.Tbody>
            </Table>
        </>
    );
};