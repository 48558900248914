import { AddFundFactsheet, AddFundFactsheetResult, FundAutoComplete, FundID, } from "../../types";
import { useFirestore, useFunctions } from "../../helpers/firebaseContext";
import { Button, Container, Divider, FileInput, Grid, Select, Stack, Text, TextInput, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { DateInput } from "@mantine/dates";
import { httpsCallable } from "firebase/functions";
// import { CustomerTagDropdownSelectComponent } from "../../components/customer_tag_dropdown_select_component";
import { transformFundsToGroupedData } from "../../routes/add_order";
import { collection, onSnapshot, query, where, } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { toBase64 } from "../../helpers/common";
import CreateFundCampaignButton from "./create_campaign_button";
import { GlobalDataContext } from "../../contexts";


const AddFundReport = () => {

    const [funds, setFunds] = useState([] as FundAutoComplete[]);
    const [chosenFund, setChosenFund] = useState<FundAutoComplete>();
    const [showCreateCampaign, setShowCreateCampaign] = useState<boolean>(false);
    const [mediaID, setMediaID] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const globalDataContext = useContext(GlobalDataContext);
    const websiteFunds = globalDataContext.privateFunds?.filter((fundObj) => fundObj.kfhc_website_id)
    const form = useForm<AddFundFactsheet>({
        initialValues: {
            titleAr: "",
            titleEn: "",
            date: new Date(),
            fundID: "",
            file: null,
            fileString: null,
        },
        validate: {
            file: (value: any): string | null => {
                if (!value)
                    return "Select a PDF";
                if (value.size > 2 * 1024 * 1024) { // 2MB size limit
                    return ('File size should not exceed 2MB');
                }
                return null
            },
            fundID: (value: any): string | null => {
                return !value || value === "" ? "Select a Fund" : null;
            }
            , titleAr: (value: any): string | null => {
                return !value || value === "" ? "write an arabic title" : null;
            }
            , titleEn: (value: any): string | null => {
                return !value || value === "" ? "write an english title" : null;
            }
            , date: (value: any): string | null => {
                return !value ? "Select a date " : null;
            }

        }
    },);

    const functions = useFunctions();
    const [msg, setMsg] = useState(null as any);
    const [isError, setIsError] = useState(false);
    //get funds
    useEffect(() => {

        let fundArray = [] as FundAutoComplete[];

        websiteFunds?.forEach((fundData) => {
            fundArray.push({
                ...fundData,
                label: `${fundData.name_en} - ${fundData.name_ar}`,
                value: fundData.id,
            });
        });
        setFunds(fundArray);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [websiteFunds]);

    return (
        <Container size="xl" bg="white" style={{ borderRadius: 14, }} p={14}>

            <form
                onSubmit={form.onSubmit(async (formValues, _event) => {
                    let fileObj: string | ArrayBuffer | null = null
                    if (formValues?.file)
                        fileObj = await toBase64(formValues?.file)
                    try {
                        setLoading(true);
                        setIsError(false);
                        setMsg(null);

                        const addFactSheet = httpsCallable<AddFundFactsheet, AddFundFactsheetResult>(functions, "addFundFactsheet");
                        const res = await addFactSheet({ ...formValues, fileString: fileObj });
                        console.log(res);
                        if (res.data.status === "error") {
                            setIsError(true);
                            setMsg(<Text c="red">{res.data?.message}</Text>)

                        }
                        else {
                            setIsError(false);

                            setMsg(<Text c="green">{res.data?.message}</Text>)
                            setShowCreateCampaign(true);
                            setMediaID(res.data?.mediaID!);
                            //created campaign
                        }
                        setLoading(false);
                    }
                    catch (e: any) {
                        setIsError(true);
                        setMsg(<Text c={"red"}>Validation error: {Object.keys(e).map((key) => <Text>{key}: {e[key]}</Text>)}</Text>)
                        setLoading(false);

                    }
                }, (validationErrors, _values, _event) => {
                    // console.log("Handle enter");

                    setIsError(true);
                    setMsg(<Text>Validation error: {Object.keys(validationErrors).map((key) => <Text>{key}: {validationErrors[key]}</Text>)}</Text>)
                })}
            >

                <Title order={2}>Add Fund Report/Factsheet</Title>
                <Stack mt={30}>


                    <Grid justify="center">

                        <Grid.Col span={{ xs: 12, lg: 4 }}>


                            <Select

                                label="Select Fund"

                                {...form.getInputProps("fundID")}
                                miw={400}
                                maxDropdownHeight={400}
                                value={form.values?.fundID}
                                data={transformFundsToGroupedData(funds)}
                                placeholder="Choose fund "
                                onChange={(value) => {
                                    //get the fund object
                                    let fund = funds.find((fund) => fund.id === value);
                                    if (!fund) return;

                                    form.setFieldValue("fundID", fund.id);
                                    setChosenFund(fund);
                                    // setSelectedFund(fund as Fund);
                                    // recalcAmount();
                                }}

                            />
                        </Grid.Col>
                        <Grid.Col span={{ xs: 12, lg: 3 }}>
                            <FileInput
                                required
                                label="PDF File"
                                value={form.values?.file}
                                accept="application/pdf"

                                {...form.getInputProps("file")}

                            />
                        </Grid.Col >
                        <Grid.Col span={{ xs: 12, lg: 3 }}>
                            <DateInput

                                label="Date"


                                value={form.values?.date}
                                {...form.getInputProps("date")}


                            />
                        </Grid.Col >
                        <Divider />
                        <Grid.Col span={{ xs: 12, lg: 5 }}>
                            <TextInput
                                label="title (En)"
                                value={form.values?.titleEn || ""}
                                {...form.getInputProps("titleEn")}


                            />
                        </Grid.Col >

                        <Grid.Col span={{ xs: 12, lg: 5 }}>
                            <TextInput

                                label="title (Ar)"


                                value={form.values?.titleAr || ""}

                                {...form.getInputProps("titleAr")}
                            />
                        </Grid.Col >
                        <Divider />


                    </Grid>
                </Stack>

                <Grid justify="center" mt={30}>

                    <Grid.Col span={{ xs: 12, lg: 2 }}>

                        <Stack mt={14}>
                            <Button type="submit" loading={loading}>
                                Submit
                            </Button>
                        </Stack>
                    </Grid.Col>
                </Grid>
                {(isError || msg) && <Stack mt={14} c="red">
                    {msg}
                </Stack>}

                {
                    showCreateCampaign && <CreateFundCampaignButton fund={chosenFund} variables={{
                        mediaID: mediaID,
                        date: form.values?.date?.toLocaleDateString("en-GB"),
                        fundName: chosenFund?.label
                    }} />
                }



            </form >
        </Container>
    )
}

export default AddFundReport