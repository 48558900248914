import { useContext, useEffect, useState } from "react";
import { useForm, UseFormReturnType } from "@mantine/form";
import { httpsCallable } from "firebase/functions";

import {
  Alert,
  Checkbox,
  Group,
  Modal,
  Radio,
  Space,
  Textarea,
  Text,
  SegmentedControl,
  Stack,
  Select,
  Grid,
  ActionIcon,
  SelectProps
} from "@mantine/core";
import { Button } from "@mantine/core";
import { onSnapshot, collection, doc } from "firebase/firestore";

import { IconAlertCircle, IconRefresh } from "@tabler/icons-react";
import SenderIDSelect from "./SenderIDSelect";
import { CustomClaims, Customer, RequestKYCUpdateRequest, RequestKYCUpdateResult, TwilioContentType } from "../types";
import axios from "axios";
import { AppConfigContext, MyUserDataContext } from "../contexts";
import { modals } from "@mantine/modals";
import { useFirestore, useFunctions } from "../helpers/firebaseContext";


export const refreshWhatsappTemplates = async (
  useWhatsapp: boolean,
  form: UseFormReturnType<any>,
  apiUrl: string,
  setWhatsappTemplates: (value: TwilioContentType[]) => void,
  filterMesgByLanguage?: boolean,
  setLoadingWhatsappTemplates?: (value: boolean) => void,
  // templateID?: string, // to get specific template
) => {

  if (useWhatsapp) {
    try {

      if (setLoadingWhatsappTemplates) {
        setLoadingWhatsappTemplates(true)
      }


      const { data: templates } =
        await axios.get(`${apiUrl}/getWhatsappTemplatesRequest`, {
          // params: {
          //   templateID: templateID // to fetch specified tempalte only
          // }
        });



      let updatedWhatsappTemplates = templates?.data?.contents

      if (filterMesgByLanguage) {

        updatedWhatsappTemplates = updatedWhatsappTemplates?.filter((template: any) => {
          if (form.getInputProps("language").value === "English") {
            return template.language === "en"
          }
          else {
            return template.language === "ar"
          }
        })
      }

      await setWhatsappTemplates(updatedWhatsappTemplates);

      if (updatedWhatsappTemplates?.length === 1) {
        console.log("setting template", updatedWhatsappTemplates[0]);

        // setSelectedWhatsappTemplate(updatedWhatsappTemplates[0])
        form.setFieldValue("selectedWhatsappTemplate", updatedWhatsappTemplates[0])
        console.log("setting template in from ", form.values?.selectedWhatsappTemplate);

      }
      else {
        form.setFieldValue("selectedWhatsappTemplate", undefined)

      }
      if (setLoadingWhatsappTemplates) {
        setLoadingWhatsappTemplates(false)
      }
    } catch (e: any) {
      if (setLoadingWhatsappTemplates) {
        setLoadingWhatsappTemplates(false)
      }
      modals.openConfirmModal({
        title: 'Error Fetching Tempalte',
        centered: true,
        children: (
          <Text size="sm">
            {e?.message || "an error has occured"}
          </Text>
        ),
        labels: { confirm: 'try again', cancel: "cancel" },
        confirmProps: { color: 'red' },
        onCancel: () => console.log('Cancel'),
        onConfirm: () => refreshWhatsappTemplates(useWhatsapp, form, apiUrl, setWhatsappTemplates, true,
          // templateID
        ),
      });

    }
  }

}

export const refreshEmailTemplates = async (
  useEmail: boolean,
  form: UseFormReturnType<any>,
  apiUrl: string,
  setEmailTemplates: (value: any[],) => void,
  setLoadingEmailTemplates?: (value: boolean) => void,
  // templateID?: string // to fetch specific template
) => {
  if (useEmail) {
    try {
      if (setLoadingEmailTemplates) { setLoadingEmailTemplates(true) }

      const { data: templates } =
        await axios.get(`${apiUrl}/getSendgridTemplatesRequest`, {
          // params: {
          //   templateID: templateID
          // }
        });



      let updatedEmailTemplates = templates?.data?.templates?.filter((templateObj: any) => templateObj?.versions?.length)


      await setEmailTemplates(updatedEmailTemplates);
      if (updatedEmailTemplates?.length === 1) {
        console.log("setting template", updatedEmailTemplates[0]);


        form.setFieldValue("selectedEmailTemplate", updatedEmailTemplates[0])
        console.log("setting template in from ", form.values?.selectedWhatsappTemplate);

      }
      else {


        form.setFieldValue("selectedEmailTemplate", undefined)

      }
      if (setLoadingEmailTemplates) { setLoadingEmailTemplates(false) }

    } catch (e: any) {
      if (setLoadingEmailTemplates) { setLoadingEmailTemplates(false) }

      modals.openConfirmModal({
        title: 'Error Fetching Email Tempalte',
        centered: true,
        children: (
          <Text size="sm">
            {e?.message || "an error has occured"}
          </Text>
        ),
        labels: { confirm: 'try again', cancel: "cancel" },
        confirmProps: { color: 'red' },
        onCancel: () => console.log('Cancel'),
        onConfirm: () => refreshEmailTemplates(useEmail, form, apiUrl, setEmailTemplates, setLoadingEmailTemplates,
          // templateID
        ),
      });

    }
  }

}
export default function SendSMSModal(props: any) {
  const rows = props.rows;
  const opened = props.opened;
  const setOpened = props.setOpened;

  const functions = useFunctions();
  const db = useFirestore();

  const appConfigContext = useContext(AppConfigContext);
  const userContext = useContext(MyUserDataContext);
  const customClaims = userContext.firebaseTokenResult?.claims.customClaims as CustomClaims;

  const [requirePassport, setRequirePassport] = useState(false);
  const [useWhatsapp, setUseWhatsapp] = useState(false);
  const [useEmail, setUseEmail] = useState(false);
  const [selectedWhatsappTemplate, setSelectedWhatsappTemplate] = useState<TwilioContentType & SelectProps>();
  const [selectedEmailTemplate, setLoadingEmailTemplates] = useState<any>();
  const [usePACI, setUsePACI] = useState(true);

  // const [rejectionOpened, setRejectionOpened] = useState(false);
  // const [confirmDialogError, setConfirmDialogError] = useState<any>(null);
  const [confirming, setConfirming] = useState(false);
  const [msg, setMsg] = useState<any>();

  const form = useForm<RequestKYCUpdateRequest>({
    initialValues: {
      updateLink: {
        updateType: "none",
        customerID: "",  //this will be filled out by the server.
      },
      customers: [],
      // smsMessage: "",
      // updateType: "none",
      // senderID: "KFHC-KYC",
      language: "English",
    },
  });

  const [SMSSampleText, setSMSSampleText] = useState("");
  const [SMSText, setSMSText] = useState("");
  const [SMSDocID, setSMSDocID] = useState("");
  const [whatsappTemplates, setWhatsappTemplates] = useState<TwilioContentType[]>([]);
  const [emailTemplates, setEmailTemplates] = useState<any[]>([]);


  function replaceText(text: string, obj: any, start = "{{", end = "}}") {
    return text?.replace(new RegExp(`${start}(.+?)${end}`, "g"), (_, part) => {
      return part?.split(".").reduce((o: any, k: any) => (o || {})[k], obj);
    });
  }

  const sampleObj = {
    name_en: "Yousef",
    name_ar: "يوسف",
  };
  let SMSTextChanged = (text: string) => {
    setSMSText(text);

    let s = rows.length > 0 ? rows[0] : sampleObj;

    //ok figure out sample text stuff processing
    setSMSSampleText(replaceText(text, s));
  };



  useEffect(() => {
    const getData = async () => {

      await refreshWhatsappTemplates(useWhatsapp, form, appConfigContext.apiUrl!, setWhatsappTemplates, true)
      await refreshEmailTemplates(useEmail, form, appConfigContext.apiUrl!, setEmailTemplates, setLoadingEmailTemplates)

    }
    if (opened) { getData() }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened])

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => {
          setMsg(null);
          setOpened(false);
        }}
        title="Send SMS"
      >
        <form

          onSubmit={form.onSubmit(async (values) => {
            console.log(values);

            //get token, somehow.
            setConfirming(true);


            // setConfirmDialogError(null);
            setMsg(null);


            try {
              //generate a doc ID
              const docID = doc(collection(db, "sms")).id;
              setSMSDocID(docID);

              //create links with updateType and passportRequired
              const requestKYCUpdateFunction = httpsCallable<RequestKYCUpdateRequest, RequestKYCUpdateResult>(
                functions,
                "requestKYCUpdate",
                {
                  timeout: 540000
                }
              );

              //TESTING: send 250 requests to the first guy
              // let yousefs = [];
              // for (let i = 0; i < 250; i++) {
              //   yousefs.push(rows[0]);
              // }

              //filter out what we need.  we don't need an 11mb request sent with everything.
              let customers = rows.map((customer: Customer) => {
                // let customers = yousefs.map((customer: Customer) => {
                return {
                  civilID: customer.civilID,
                  email: customer.email,
                  gender: customer.gender,
                  id: customer.id,
                  mobile: customer.mobile,
                  name_ar: customer.name_ar,
                  name_en: customer.name_en,
                  nationality: customer.nationality,
                }
              });

              // return;

              let d: RequestKYCUpdateRequest = {
                customers: customers,
                updateLink: {
                  updateType: values.updateLink.updateType,
                  senderID: values.updateLink.senderID,
                  customerID: values.updateLink.customerID,
                  smsMessage: SMSText,
                  smsDocID: docID,
                  passportRequired: requirePassport,
                  usePACI: usePACI,
                  useWhatsapp: useWhatsapp,
                  useEmail: useEmail,
                  selectedWhatsappTemplate: selectedWhatsappTemplate,
                  selectedEmailTemplate: selectedEmailTemplate,
                },
              };
              // console.log(d);
              // return;

              const res = await requestKYCUpdateFunction(d);

              // console.log(res);

              //loop through smsResult and check if any have failed.
              //if any have failed, show error message and don't close modal.
              if (
                (res.data.smsResult!).some(
                  (x: any) => x.status === "rejected"
                )
              ) {
                //show error message.
                // setConfirmDialogError();
                setMsg({ type: "error", message: "Some messages failed to send..." });

              }

              // setIsError(true);
              setMsg({ type: "success", message: "Success" });
            } catch (error: any) {
              // setIsError(true);
              console.log(error);
              // setMsg(error.message);
              // setMsg({type:"error",msg:});
              setMsg({ type: "error", message: error.message });


              // setConfirmDialogError(error.message);
            }

            setConfirming(false);
          })}
        >
          <Space h="sm" />
          {/* <Grid ta="center"> */}
          {/* <Grid.Col span={4}> */}

          <Radio.Group
            label="Update Type"
            required
            description="What type of update would you like the client to do?"
            {...form.getInputProps("updateLink.updateType")}
          >
            <Group>
              <Radio value="submit" label="New KYC" />
              {/* <Radio value="update" label="Update KYC" /> */}
              <Radio value="upload_docs" label="Upload documents" />
              <Radio value="broadcast" label="Broadcast" />
            </Group>
          </Radio.Group>

          {/* </Grid.Col> */}
          {/* <Grid.Col span={8}> */}
          <Space h="xl" />

          <Checkbox
            label="Require Passport"
            checked={requirePassport}
            onChange={(e) => setRequirePassport(e.target.checked)}
          />
          <Space h="xs" />
          {customClaims["admin"] && <>

            <Checkbox
              label={
                <Grid gutter={8}>
                  <Grid.Col span={useWhatsapp ? 9 : 12}>

                    Use Whatsapp
                  </Grid.Col>
                  {useWhatsapp && <Grid.Col span={3}>

                    <ActionIcon
                      onClick={async () => await refreshWhatsappTemplates(useWhatsapp, form, appConfigContext.apiUrl!, setWhatsappTemplates, true)}
                      variant="subtle" aria-label="refres-templates">

                      <IconRefresh stroke={1.5} />
                    </ActionIcon>
                  </Grid.Col>}

                </Grid>
              }
              checked={useWhatsapp}
              onChange={(e) => setUseWhatsapp(e.target.checked)}
            />


            <Space h="xs" />

            {<Checkbox
              label={
                <Grid gutter={8}>
                  <Grid.Col span={useEmail ? 9 : 12}>

                    Send Email
                  </Grid.Col>
                  {useEmail && <Grid.Col span={3}>

                    <ActionIcon
                      onClick={async () => await refreshEmailTemplates(useEmail, form, appConfigContext.apiUrl!, setEmailTemplates, setLoadingEmailTemplates)}
                      variant="subtle" aria-label="refres-templates">

                      <IconRefresh stroke={1.5} />
                    </ActionIcon>
                  </Grid.Col>}

                </Grid>
              }
              checked={useEmail}
              onChange={(e) => setUseEmail(e.target.checked)}
            />}


            <Space h="xs" />
          </>}
          <Checkbox
            label="Use PACI Authentication"
            checked={usePACI}
            onChange={(e) => setUsePACI(e.target.checked)}
          />
          <Space h="xl" />
          {
            !(useWhatsapp || useEmail) && <>
              <SenderIDSelect
                {...form?.getInputProps("updateLink.senderID")}
                label="Sender Identity"
              />


              <Space h="xl" />
            </>

          }

          <SegmentedControl
            {...form.getInputProps("language")}


            data={[
              { label: 'English', value: 'English' },
              { label: 'عربي', value: 'Arabic' },
            ]}
          />
          <Space h="xl" />

          {useWhatsapp &&

            <Select
              label="Select whatsapp  template"
              value={selectedWhatsappTemplate?.value}
              onChange={(value, option: any) => {
                setSelectedWhatsappTemplate(option)
                SMSTextChanged((option?.types["twilio/text"] || option?.types["twilio/media"]) ? (option?.types["twilio/text"]?.body || option?.types["twilio/media"]?.body) : option?.types["twilio/call-to-action"]?.body)
              }}
              data={whatsappTemplates.map((template: TwilioContentType) => {
                return {
                  ...template,
                  value: template.sid,
                  label: template?.friendly_name
                }
              })}
            />
          }
          {
            useEmail &&

            <Select
              label="Select Email template"
              value={selectedEmailTemplate}
              onChange={(value: string | null, option: any) => {
                setLoadingEmailTemplates(value)
              }}
              data={emailTemplates.map((template: any) => {
                return {
                  ...template,
                  value: template.id,
                  label: template?.name
                }
              })}
            />



          }
          {!(useWhatsapp || useEmail) && <>
            <Textarea
              placeholder="Type in SMS message"
              label="Message"
              {...form.getInputProps("updateLink.smsMessage")}
              minRows={2}
              autosize
              value={SMSText}
              onChange={(e) => SMSTextChanged(e.currentTarget.value)}
            />

            <Text fs="italic" c="dimmed" size="xs">
              You can use variables like {"{{name_en}}"} and {"{{name_ar}}"}{" "}
            </Text>


          </>}

          <p>

            <Text size="xs">SAMPLE</Text>
          </p>
          <p><Text c="blue" dir={form.getInputProps("language").value === "Arabic" ? "rtl" : "ltr"}>{SMSSampleText}</Text></p>


          <Space h="xl" />
          <Group>
            <Button color="grape" type="submit" loading={confirming}>
              Send SMS to {rows.length} customers
            </Button>
            <MessagesSent docID={SMSDocID} />
          </Group>
          {/* {confirmDialogError && (
            <Alert
              icon={<IconAlertCircle size={16} />}
              title="Whoops!"
              c="red"
            >
              {confirmDialogError}
            </Alert>
          )} */}
          <Space h="xl" />

          {msg && (
            <Alert
              icon={<IconAlertCircle size={16} />}
              color={msg.type === "success" ? "green" : "red"}
              title={msg.type === "success" ? "Success" : "Failure"}
            >
              {msg.message}
            </Alert>
          )}
        </form>
      </Modal >
    </>
  );
}
interface MessagesSentProps {
  docID: string;
}

export const MessagesSent = ({ docID }: MessagesSentProps) => {
  //get document with docID and all its details
  const db = useFirestore();
  const [messagesSent, setMessagesSent] = useState(null);
  const [messagesTotal, setMessagesTotal] = useState(null);
  const [messagesFailed, setMessagesFailed] = useState(null);
  const [smsSendingStatus, setSMSSendingStatus] = useState(null);

  const [errors, setErrors] = useState(
    null as { [key: string]: string } | null
  );

  useEffect(() => {
    if (!docID) return;

    return onSnapshot(doc(db, "sms", docID), (snapshot) => {
      setMessagesSent(snapshot.data()?.messagesSent);
      setMessagesTotal(snapshot.data()?.messagesTotal);
      setMessagesFailed(snapshot.data()?.messagesFailed);
      setSMSSendingStatus(snapshot.data()?.smsSendingStatus);
      setErrors(snapshot.data()?.errors);
    });
  }, [db, docID]);




  return (
    <Stack>
      <Text fs="italic" c="violet" size="xs">{smsSendingStatus}</Text>
      <Group>
        <Text fs="italic" c="dimmed" size="xs">
          {messagesSent != null
            ? `Sent to ${messagesSent} / ${messagesTotal}`
            : ""}{" "}
        </Text>
        {messagesFailed && messagesFailed > 0 && (
          <Text fs="italic" c="red" size="xs">
            {messagesFailed} failed
            <br />

            {errors && Object.keys(errors)?.map((errorKey) => {
              return <>
                {errorKey} : {errors[errorKey]}
                <br />
              </>
            })}

          </Text>
        )}
      </Group>
    </Stack>
  );
}
