import { Badge, Button, Card, Container, Group, Image, Text } from '@mantine/core';

const CommingSoon = () => {





    return <Container style={{ height: "100%", justifyContent: "center", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>

            <Image
                src="./Portal.png"
                height={"100%"}
            // alt="Norway"
            />
            {/* <Image
                src="./coming-soon.png"
                height={"100%"}
            // alt="Norway"
            /> */}
        </div>
    </Container>
};



export default CommingSoon;