import React from 'react';
import {
    Card,
    Text,
    Group,
    Title,
    Paper,
    ThemeIcon,
    Box,
    Grid,
    rem,
    Tooltip,
} from '@mantine/core';
import {
    IconArrowUpRight,
    IconArrowDownRight,
    IconActivity,
    IconAlertCircle
} from '@tabler/icons-react';
import { Fund } from '../types';
import { dateFormater, getDaysDifference } from '../helpers/common';

interface FundCardProps {
    fund: Fund;
}

const FundCard: React.FC<FundCardProps> = ({ fund }) => {
    const getReturnColor = (value: number | undefined) => {
        if (!value) return 'gray';
        return value >= 0 ? 'teal' : 'red';
    };

    const Metric = ({ label, value, isPercentage = false, highlighted = false, currency = 'KWD', subtitle, precision = 2 }: {
        label: string;
        value: number | undefined;
        isPercentage?: boolean;
        highlighted?: boolean;
        currency?: string;
        subtitle?: string;
        precision?: number;
    }) => (
        <Box>
            <Text size="sm" c="dimmed" mb={2}>
                {label}
            </Text>
            <Group gap="xs">
                {isPercentage && value && (
                    <ThemeIcon size="sm" variant="light" color={getReturnColor(value)}>
                        {value >= 0 ? <IconArrowUpRight size={rem(14)} /> : <IconArrowDownRight size={rem(14)} />}
                    </ThemeIcon>
                )}
                {value && (
                    <>
                        <Text
                            fw={700}
                            size={highlighted ? "xl" : "lg"}
                            c={isPercentage ? getReturnColor(value) : (highlighted ? "blue.7" : undefined)}
                        >
                            {value?.toFixed(precision)}{isPercentage ? '%' : " " + currency}
                        </Text>
                    </>
                )}
                {!value && (
                    <Text
                        fw={700}
                        size={highlighted ? "xl" : "lg"}
                        c={highlighted ? "blue.7" : undefined}
                    >
                        -
                    </Text>
                )}
            </Group>
            {subtitle && (
                <Text size="xs" mt={2}>
                    {subtitle}
                </Text>
            )}
        </Box>
    );


    const navSubtitle = "As of " + dateFormater(fund.nav_date?.toDate());
    const inceptionDateSubtitle = "Inception: " + dateFormater(fund.inception_date?.toDate(), true);

    const daysSinceLastUpdated = getDaysDifference((fund.nav_date?.toDate() ?? new Date()), new Date());
    const isNavOld = daysSinceLastUpdated > 7;

    return (
        <Card
            shadow="sm"
            padding="md"
            radius="md"
            style={{ width: '100%' }}
            withBorder
        >
            {/* Header */}
            <Group mb="md">
                <Box>
                    <Grid>
                        <Grid.Col span={isNavOld ? 11 : 12}>
                            <Title order={3} c="#2E8BC0">
                                {fund.name_en}
                            </Title>
                        </Grid.Col>
                        {isNavOld && <Grid.Col span={1}>
                            <Tooltip label="Note: NAV hasn't been updated in over a week, data may be outdated." transitionProps={{ transition: 'pop', duration: 200 }}><IconAlertCircle color="orange" size={rem(14)} /></Tooltip>

                        </Grid.Col>}
                    </Grid>
                    <Group gap="xs" mt={4}>
                        <ThemeIcon size="sm" variant="light" color="blue">
                            <IconActivity size={rem(14)} />
                        </ThemeIcon>
                        <Text size="sm" c="dimmed">
                            Fund Performance
                        </Text>
                    </Group>
                </Box>
            </Group>

            {/* NAV and Returns For Money Market */}
            {fund.asset_class?.includes('Money Market') &&
                <Paper p="md" radius="md" withBorder mb="md">
                    <Grid>
                        <Grid.Col span={6}>
                            <Metric label="NAV" value={fund.nav_per_unit} currency={fund.currency} subtitle={navSubtitle} precision={5} />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <Metric label="Return Since Inception" value={fund.itd} isPercentage subtitle={inceptionDateSubtitle} />
                        </Grid.Col>




                    </Grid>
                    <Grid>
                        <Grid.Col span={6}>
                            <Metric label="Weekly Annualized Return" value={fund.weekly_annualized_return} isPercentage />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <Metric label="Yearly Annualized Return" value={fund.yearly_annualized_return} isPercentage />
                        </Grid.Col>

                    </Grid>
                </Paper>}
         {/* NAV and Returns For Others */}
            {!fund.asset_class?.includes('Money Market') &&
                <Paper p="md" radius="md" withBorder mb="md">
                    <Grid>
                        <Grid.Col span={4}>
                            <Metric label="NAV" value={fund.nav_per_unit} currency={fund.currency} subtitle={navSubtitle} />
                        </Grid.Col>
                        <Grid.Col span={4}>
                            <Metric label="MTD Return" value={fund.mtd} isPercentage />
                        </Grid.Col>
                        <Grid.Col span={4}>
                            <Metric label="YTD Return" value={fund.ytd} isPercentage />
                        </Grid.Col>


                    </Grid>
                    <Grid>
                        <Grid.Col span={4}>
                            <Metric label="Return Since Inception" value={fund.itd} isPercentage subtitle={inceptionDateSubtitle} />
                        </Grid.Col>
                        <Grid.Col span={4}>
                            <Metric label="Highest NAV" value={fund.highest_nav} currency={fund.currency} subtitle={dateFormater(fund.highest_nav_date?.toDate())} />
                        </Grid.Col>
                        <Grid.Col span={4}>
                            <Metric label="Lowest NAV" value={fund.lowest_nav} currency={fund.currency} subtitle={dateFormater(fund.lowest_nav_date?.toDate())} />
                        </Grid.Col>


                    </Grid>
                </Paper>}

            <Text c={daysSinceLastUpdated > 7 ? 'orange' : 'black'}>
                {daysSinceLastUpdated === 0 ? 'Updated today' : `Updated ${daysSinceLastUpdated} days ago`}
            </Text>
        </Card>
    );
};

export default FundCard;