import { Group, Paper, Space, Text, ThemeIcon, Title } from "@mantine/core";
import { IconAlertCircle, IconCheck, IconX } from "@tabler/icons-react";
import { ReactNode } from "react";

interface CustomInfoCardProps {
    title: string | Element | ReactNode;
    list: {
        label: string | Element | ReactNode;
        value: any;
        noCapitalize?: boolean,
    }[];
    icon: any;
    color: string;
    attention?: boolean;
}
export const CustomInfoCard = ({ title, list, icon, color, attention, ...props }: CustomInfoCardProps) => {
    //can also convert arrays by recursively calling this function.  Can break in amazing fashion if array contents are not strings.
    const convertCamelCase = (str: any): string => {

        if (typeof str === "string") {
            return str.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1");
        } else if (Array.isArray(str)) {
            return str.map((item: any) => convertCamelCase(item))?.join(", ");
        }
        return str;
    };

    return (
        <Paper shadow="xs" p="md" style={ attention?{
            backgroundColor: 'rgba(255, 193, 7, 0.1)', // semi-transparent orange background
          } : {}}>
            <Group>
                <ThemeIcon color={color} variant="light">
                    {icon}
                </ThemeIcon>
                <Title order={5}>
                </Title>
                {title as ReactNode}
                {attention && <IconAlertCircle color="orange" size={24} />}
            </Group>
            <Space h="xs" />
            {/* <Grid gutter="xs"> */}
            {list
                .filter((item) => {
                    return item.value != null;
                })
                .map((item, idx) => {
                    let key = idx;
                    let value: JSX.Element;

                    //string?  format it properly.
                    if (typeof item.value === "string") {
                        value = <Text tt={item.noCapitalize ? undefined : "capitalize"} fw={500} size="sm" ta="right">
                            {item.noCapitalize ? item.value : convertCamelCase(item.value)}
                        </Text>
                    }
                    //boolean?  show a checkmark or an X
                    else if (typeof item.value === "boolean") {
                        value = item.value ? (
                            <ThemeIcon radius="xl" size="xs" color="green">
                                {" "}
                                <IconCheck size={12} />{" "}
                            </ThemeIcon>
                        ) : (
                            <ThemeIcon radius="xl" size="xs" color="red">
                                <IconX size={12} />
                            </ThemeIcon>
                        );
                    }
                    //neither?  Maybe it's just a JSX element.  show the damn thing anyway.
                    else value = item.value;


                    return (
                        <Group key={key} justify="space-between">
                            <Text tt="capitalize" size="sm" c="dimmed">
                                {convertCamelCase(item.label)}
                            </Text>{" "}
                            {value}
                        </Group>
                    );
                })}
            {/* </Grid> */}
        </Paper>
    );
};