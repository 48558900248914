import { Button, Card, Paper, Space, Text } from '@mantine/core'
import { modals } from '@mantine/modals';
import { collection, doc } from 'firebase/firestore';
import React, { useContext, useState } from 'react'
import { Agreement, AvailableAgreements, BaseResult, Customer, SendAgreementSMSRequest, UserDataType } from '../types';
import { httpsCallable } from 'firebase/functions';
import { useFirestore, useFunctions } from '../helpers/firebaseContext';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { MyUserDataContext } from '../contexts';


export interface AskForConsentProps {
    customerData: Customer;
}


const AskForConsent = ({ customerData }: AskForConsentProps) => {

    const functions = useFunctions();
    functions.region = "europe-west1";
    const db = useFirestore();

    const userData: UserDataType = useContext(MyUserDataContext);
    // let userClaims = userData.firebaseTokenResult?.claims.customClaims as CustomClaims || {};


    const [pendingAskForConsent, setPendingAskForConsent] = useState<boolean>(false);
    const sendAgreementSMS = httpsCallable<SendAgreementSMSRequest, BaseResult>(functions, "sendAgreementSMS");

    return (

        <Paper shadow="xs" p="md">
            <Text>
                The client has not yet agreed to allow you to access their investment information.  Please ask for consent before proceeding.
            </Text>
            <Space h="xs" />




            <Button
                color="yellow"
                loading={pendingAskForConsent}
                type="button"
                name="ask_for_consent"
                onClick={async () => {

                    modals.openConfirmModal({
                        title: 'Please confirm your action',
                        children: (
                            <Text size="sm">
                                I acknowledge that all the data and information related to the client, as specified in the authorization, are considered confidential and protected under the laws, regulatory instructions, and systems in force at Kuwait Finance House Group. It is prohibited to disclose or use such information for purposes other than those authorized by the client and for their benefit. I commit to protecting Kuwait Finance House Group from any losses, damages, or expenses that may arise from my misconduct, intentional actions, negligence, or failure to comply with the rules, laws, and systems in place regarding the protection of client information confidentiality.
                            </Text>
                        ),
                        labels: { confirm: 'Ask for Consent', cancel: 'Cancel' },
                        confirmProps: { loading: pendingAskForConsent },
                        onCancel: () => console.log('Cancel'),
                        onConfirm: async () => {
                            //send API request to create AMS
                            setPendingAskForConsent(true);
                            try {
                                //generate a doc ID
                                const docID = doc(collection(db, "sms")).id;

                                //maybe this agreement should be in firestore somewhere.
                                const agreement: Agreement = {
                                    customerObj: customerData,
                                    agreement_id: AvailableAgreements.kfh_rm_access_consent,
                                    title_en: "KFH Consent",
                                    title_ar: "تفويض لبيت التمويل الكويتي",
                                    content_en: "I agree to award Kuwait Finance House the right to view all information and data related to my investments with (KFH Capital Investment Company)",
                                    content_ar: "أوافق على منح بيت التمويل الكويتي حق الإطلاع على جميع المعلومات والبيانات الخاصة بإستثماراتى لدى (شركة بيتك كابيتال للإستثمار)",
                                    paci_msg_en: "Agreeing to grant KFH access to my investment data with KFH Capital.",
                                    paci_msg_ar: "الموافقة على منح بيت التمويل الكويتي حق الإطلاع على بيانات استثماراتي لدى بيتك كابيتال."
                                }

                                const res = await sendAgreementSMS({
                                    agreement: agreement,
                                    token: await userData.getAzureADToken!(userData),
                                    smsDocID: docID,
                                });

                                showNotification({
                                    id: "consent",
                                    color: "green",
                                    icon: <IconCheck />,
                                    withBorder: true,
                                    message: `Consent request for customer ${customerData.name_en} sent to ${customerData.mobile}`,
                                    autoClose: true,
                                });

                                console.log("Result from sendAgreementSMS", res);
                            } catch (error: any) {
                                showNotification({
                                    id: "error",
                                    color: "red",
                                    icon: <IconX />,
                                    withBorder: true,
                                    message: `Error asking for consent: ${error.message}`,
                                    autoClose: true,
                                });

                            }
                            setPendingAskForConsent(false);
                        },
                    });
                }}
            >
                Ask for Consent
            </Button>
        </Paper>

    )
}

export default AskForConsent