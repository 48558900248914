import { Accordion, Table, Title, Text, Group, ThemeIcon, Badge, SimpleGrid, Paper, Space, Tooltip, Collapse, Card } from '@mantine/core';
import React, { ReactNode, useContext, useState } from 'react';
import { ConfigDataType, Customer, CustomerFundData, CustomerFundDataTransaction, Fund, Portfolio, Position } from '../../types';
import { IconArrowDown, IconArrowUp, IconCash, IconChartLine, IconChevronDown, IconFolder, IconGlobe } from '@tabler/icons-react';
import { CustomInfoCard } from './CustomInfoCard';
import NumberDisplay from '../../components/NumberDisplay';
import { ConfigContext, GlobalDataContext, MyUserDataContext } from '../../contexts';
import { FundSummarizedData } from '../../components/fund_summarized_data';
import AskForConsent from '../../components/ask_for_consent';
import { PieChart, PieValueType, useDrawingArea } from '@mui/x-charts';
import { styled } from '@mui/material/styles';
import { toKwdInt } from '../../helpers/common';


export interface CustomerInfoProps {
  fundData: CustomerFundData[];
  portfoliosData: Portfolio[];
  customerData: Customer;
}

// Interface for grouped positions
interface GroupedPosition {
  exchange: string;
  currency: string;
  title: string;
  list: Position[];
  combinedPosition: Position;
}

export const CustomerInfo = ({ fundData, portfoliosData, customerData }: CustomerInfoProps) => {

  //use context data
  const configContext = useContext(ConfigContext);
  const globalDataContext = useContext(GlobalDataContext);
  const appContext = useContext(MyUserDataContext);

  let userClaims = appContext.firebaseTokenResult?.claims.customClaims;

  //Hide Portfolios for RM
  if (userClaims["kfh_rm"]) {
    portfoliosData = [];
  }

  if ((!portfoliosData || portfoliosData.length <= 0) && (!fundData || fundData.length <= 0)) {
    return <Paper ta={'center'} p={25} ><Text>There are no records to display</Text></Paper>
  }





  //If it's a KFH RM user and the customer didn't consent to access RM data, show the consent page
  if (userClaims["kfh_rm"] && !customerData.agreements?.kfh_rm_access_consent) {
    return <AskForConsent customerData={customerData} />
  }



  //arange fund to be by map by code
  const fundByCode: { [key: string]: Fund } = {};
  const openEndedFundsCode: string[] = [];

  globalDataContext.funds?.map((fund) => {
    if (fund.code) {
      fundByCode[fund.code] = fund;
      if (fund.type == "open_ended") {
        openEndedFundsCode.push(fund.code);
      }
    }

  });

  let openEndedFundData = fundData.filter((fundInPosition) => (openEndedFundsCode.includes(fundInPosition.gl_code ?? '')));
  let closeEndedFundData = fundData.filter((fundInPosition) => (!openEndedFundsCode.includes(fundInPosition.gl_code ?? '')));
  let defaultAccordionValue = ""
  if (openEndedFundData.length > 0) {
    defaultAccordionValue = "openEndedFunds";
  } else if (closeEndedFundData.length > 0) {
    defaultAccordionValue = "closeEndedFunds";
  }

  return (
    <Accordion chevronPosition="right" variant="contained" defaultValue={defaultAccordionValue}>

      {openEndedFundData.length > 0 &&
        <Accordion.Item value="openEndedFunds" key="openEndedFunds">
          <Accordion.Control>
            <Group >
              <Title order={4}>{"Open Ended Funds"}</Title>
              <Badge color='green'>Active</Badge>
            </Group>
          </Accordion.Control>
          <Accordion.Panel>
            <Paper shadow="xs" p="md">
              <Space h="xs" />
              <FundsTable fundData={openEndedFundData} fundByCode={fundByCode} configContext={configContext} type={"open_ended"} />
            </Paper>
          </Accordion.Panel>
        </Accordion.Item>
      }
      {closeEndedFundData.length > 0 &&
        <Accordion.Item value="closeEndedFunds" key="closeEndedFunds">
          <Accordion.Control>
            <Group >
              <Title order={4}>{"Closed Ended Funds"}</Title>
              <Badge color='green'>Active</Badge>
            </Group>
          </Accordion.Control>
          <Accordion.Panel>
            <Paper shadow="xs" p="md">
              <Space h="xs" />
              <FundsTable fundData={closeEndedFundData} fundByCode={fundByCode} configContext={configContext} type={"close_ended"} />
            </Paper>
          </Accordion.Panel>
        </Accordion.Item>
      }
      {portfoliosData?.map(portfolio => <PortfolioInfo portfolio={portfolio as Portfolio} />)}
    </Accordion>
  )




}


export interface PortfolioInfoProps {
  portfolio: Portfolio;
}


// Component to display portfolio information
const PortfolioInfo = ({ portfolio }: PortfolioInfoProps) => {

  // Skip rendering if portfolio type is 145 which is funds portfolio (already added in the CustomerInfo component)
  if (portfolio.portfolioTypeID == 145) {
    return null;
  }

  // Function to get table rows for positions
  function getRows(positionType: "foreign" | "local", positions: Position[]): React.ReactNode {
    if (!positions) {
      return undefined;
    }
    positions = calculatePortfolioPercentages(positions);
    const groupedPositions = groupPositionsByExchangeAndCurrency(positions);
    let rows: JSX.Element[] = [];

    // Generate rows for each grouped position
    for (const groupedPosition of groupedPositions) {
      if (groupedPosition.exchange && groupedPosition.currency) {
        rows.push(
          <Table.Tr key={groupedPosition.title}>
            <Table.Td ta='left' colSpan={16}>{groupedPosition.title}</Table.Td>
          </Table.Tr>
        );
      }
      // Generate rows for each position within the group
      for (const element of groupedPosition.list) {
        if (element.cash) {
          rows.push(
            <Table.Tr key={element.code}>
              {positionType == "local" && <Table.Td colSpan={7}>{"Total " + element.currency + " Cash"}</Table.Td>}
              {positionType == "foreign" && <>
                <Table.Td colSpan={4}>{"Total Cash In " + element.exchange}</Table.Td>
                <Table.Td></Table.Td>
                <Table.Td></Table.Td>
              </>
              }

              <Table.Td><NumberDisplay value={element.total_market_value} withCommas /></Table.Td>
              <Table.Td></Table.Td>
              <Table.Td>-</Table.Td>
              {positionType == "foreign" && <>
                <Table.Td><NumberDisplay value={element.total_cost_year_close_local} withCommas /></Table.Td>
                <Table.Td><NumberDisplay value={element.total_market_value_local} withCommas /></Table.Td>
                <Table.Td><NumberDisplay value={element.unrealized_fx} withCommas withBrackets /></Table.Td>
                <Table.Td><NumberDisplay value={element.unrealized_equity} withCommas withBrackets /></Table.Td>
                <Table.Td><NumberDisplay value={element.total_unrealized_gain_loss_local} withCommas withBrackets /></Table.Td>
              </>}
              <Table.Td><NumberDisplay value={element.portfolio_percent} /></Table.Td>
            </Table.Tr>
          );
        } else {
          rows.push(
            <Table.Tr key={element.code}>
              <Table.Td>{element.code}</Table.Td>
              <Table.Td>{element.name}</Table.Td>
              <Table.Td><NumberDisplay value={element.quantity} withCommas /></Table.Td>
              <Table.Td><NumberDisplay value={element.bonus_shares_and_right_issues} withCommas /></Table.Td>
              <Table.Td><NumberDisplay value={element.average_cost} withCommas /></Table.Td>
              <Table.Td><NumberDisplay value={element.total_cost} withCommas /></Table.Td>
              <Table.Td><NumberDisplay value={element.total_market_value} withCommas /></Table.Td>
              <Table.Td><NumberDisplay value={element.unrealized_gain_loss} withCommas withBrackets withColor /></Table.Td>
              <Table.Td><NumberDisplay value={element.gain_loss_percent} withCommas withBrackets withColor /></Table.Td>
              {positionType == "foreign" && <>
                <Table.Td><NumberDisplay value={element.total_cost_year_close_local} withCommas /></Table.Td>
                <Table.Td><NumberDisplay value={element.total_market_value_local} withCommas /></Table.Td>
                <Table.Td><NumberDisplay value={element.unrealized_fx} withCommas withBrackets withColor /></Table.Td>
                <Table.Td><NumberDisplay value={element.unrealized_equity} withCommas withBrackets withColor /></Table.Td>
                <Table.Td><NumberDisplay value={element.total_unrealized_gain_loss_local} withCommas withBrackets withColor /></Table.Td>
              </>}
              <Table.Td><NumberDisplay value={element.portfolio_percent} /></Table.Td>
            </Table.Tr>
          );
        }

      }
      // Add total securities row if there are positions
      if ((groupedPosition.list?.length ?? 0) > 0 && groupedPosition.combinedPosition.cash == false) {
        rows.push(
          <Table.Tr key={groupedPosition.title + "2"}>
            <Table.Td colSpan={4}>{"Total Securities in " + groupedPosition.exchange}</Table.Td>

            <Table.Td></Table.Td>
            <Table.Td><NumberDisplay value={groupedPosition.combinedPosition.total_cost} withCommas /></Table.Td>
            <Table.Td><NumberDisplay value={groupedPosition.combinedPosition.total_market_value} withCommas /></Table.Td>
            <Table.Td><NumberDisplay value={groupedPosition.combinedPosition.unrealized_gain_loss} withCommas withBrackets withColor /></Table.Td>
            <Table.Td><NumberDisplay value={groupedPosition.combinedPosition.gain_loss_percent} withCommas withBrackets withColor /></Table.Td>
            {positionType == "foreign" && <>
              <Table.Td><NumberDisplay value={groupedPosition.combinedPosition.total_cost_year_close_local} withCommas /></Table.Td>
              <Table.Td><NumberDisplay value={groupedPosition.combinedPosition.total_market_value_local} withCommas /></Table.Td>
              <Table.Td><NumberDisplay value={groupedPosition.combinedPosition.unrealized_fx} withCommas withBrackets withColor /></Table.Td>
              <Table.Td><NumberDisplay value={groupedPosition.combinedPosition.unrealized_equity} withCommas withBrackets withColor /></Table.Td>
              <Table.Td><NumberDisplay value={groupedPosition.combinedPosition.total_unrealized_gain_loss_local} withCommas withBrackets withColor /></Table.Td>
            </>}

            <Table.Td><NumberDisplay value={groupedPosition.combinedPosition.portfolio_percent} /></Table.Td>
          </Table.Tr>
        );
      }
    }

    return rows;
  }

  // Function to get the positions table for a given type of position (foreign/local)
  function getPositionsTable(positionType: "foreign" | "local", positions: Position[]) {
    return <Paper shadow="xs" p="md">
      <Group>
        <ThemeIcon color="blue" variant="light">
          {<IconGlobe size={16} />}
        </ThemeIcon>
        <Title order={5}>
        </Title>
        {positionType == "foreign" ? "Foreign Position" : "Local Position" as ReactNode}
      </Group>
      <Space h="xs" />
      <Table striped withTableBorder withColumnBorders ta="center" >
        <Table.Thead>
          <Table.Tr >
            <Table.Th ta="center" >Sec. <br />No</Table.Th>
            <Table.Th ta="center" >Security <br />Name</Table.Th>
            <Table.Th ta="center" >Quantity</Table.Th>
            <Table.Th ta="center" >Bonus <br />Shares + <br />Right Issues</Table.Th>
            <Table.Th ta="center" >Avg <br />Cost</Table.Th>
            <Table.Th ta="center" >Total Cost</Table.Th>
            <Table.Th ta="center" >Total <br />Market Value</Table.Th>
            <Table.Th ta="center" >UR G/(L)</Table.Th>
            <Table.Th ta="center" >% G/(L)</Table.Th>
            {positionType == 'foreign' && <>
              <Table.Th ta="center" >Total Cost <br />(Year Close)</Table.Th>
              <Table.Th ta="center" >Total <br />Market Value</Table.Th>
              <Table.Th ta="center" >UR <br />FX</Table.Th>
              <Table.Th ta="center" >UR <br />Equity</Table.Th>
              <Table.Th ta="center" >Total UR <br />G/(L)</Table.Th>
            </>}
            <Table.Th ta="center" >%<br />Portfolio</Table.Th>
          </Table.Tr>
          {positionType == 'foreign' && <>
            <Table.Tr>
              <Table.Th colSpan={4}></Table.Th>
              <Table.Th colSpan={5} ta="center">Foreign Currency</Table.Th>
              <Table.Th colSpan={5} ta="center">Local Currency</Table.Th>
              <Table.Th colSpan={1}></Table.Th>
            </Table.Tr>
          </>}
        </Table.Thead>
        <Table.Tbody>
          {
            getRows(positionType, positions)
          }
        </Table.Tbody>
      </Table>
    </Paper>
  }


  return (
    <Accordion.Item value={portfolio.code ?? ''} key={portfolio.code}>
      <Accordion.Control>

        <Group >
          <Title order={4}>{portfolio.name}'s Portfolio</Title>
          <Badge color={portfolio.active ? 'green' : 'red'}>{portfolio.active ? 'Active' : 'Inactive'}</Badge>
        </Group>

      </Accordion.Control>
      <Accordion.Panel>
        <SimpleGrid cols={3}>
          <CustomInfoCard
            title="Portfolio Details"
            color="blue"
            icon={<IconFolder size={16} />}
            list={getPortfolioDetailsList(portfolio)}
          />
          <CustomInfoCard
            title="Position Financials"
            color="yellow"
            icon={<IconCash size={16} />}
            list={getPositionFinancialsList(portfolio)}
          />
          <CustomInfoCard
            title="Performance Metrics and Income"
            color="green"
            icon={<IconChartLine size={16} />}
            list={getPerformanceMetricsList(portfolio)}
          />
        </SimpleGrid>
        {portfolio.position?.local_position &&
          <>
            <br />
            {getPositionsTable('local', portfolio.position.local_position)}
          </>
        }
        {portfolio.position?.foreign_position &&
          <>
            <br />
            {getPositionsTable('foreign', portfolio.position.foreign_position)}
          </>
        }
      </Accordion.Panel>
    </Accordion.Item>
  )
}

// Component to display funds position table

/**
 * FundsTable component calculates and displays fund data in a table format.
 * It includes calculations for total value, Year-To-Date (YTD) distributions, and total distributions,
 * with a breakdown of Dividends (PNL) and Return on Capital (ROC).
 * Tooltips show detailed breakdowns when hovering over distributions.
 * 
 * Props:
 * - fundData: Array of customer fund data.
 * - fundByCode: Object mapping fund codes to detailed fund information.
 * - configContext: Configuration data including exchange rates.
 */
const FundsTable = ({ fundData, fundByCode, configContext, type }: { fundData: CustomerFundData[], fundByCode: { [key: string]: Fund }, configContext: ConfigDataType, type: "close_ended" | "open_ended" }) => {

  const [openedCode, setOpenedCode] = useState<string | null>();

  // Aggregated totals
  let totalValue = 0;
  let totalDividends = 0; // total PNL
  let totalReturnOnCapital = 0; // total ROC
  let totalYTDDividends = 0; // total YTD PNL
  let totalYTDReturnOnCapital = 0; // total YTD ROC

  // Storing per-fund totals in KWD
  const fundsTotalInKWD: Record<string, number> = {};
  const fundsDividendsInKWD: Record<string, number> = {}; // PNL
  const fundsReturnOnCapitalInKWD: Record<string, number> = {}; // ROC
  const fundsYTDDividendsInKWD: Record<string, number> = {}; // YTD PNL
  const fundsYTDReturnOnCapitalInKWD: Record<string, number> = {}; // YTD ROC
  const pieData: PieValueType[] = [];
  const colors: string[] = [
    "#335B74",
    "#1CADE4",
    "#2683C6",
    "#27CED7",
    "#42BA97",
    "#3E8853",
    "#62A39F",
    "#6EAC1C",
    "#3EBA53",
    "#285C73",  // Additional color
    "#90D3E5",  // Additional color
    "#107BBF",  // Additional color
    "#12C3B8",  // Additional color
    "#5EC587",  // Additional color
    "#46A367",  // Additional color
  ];

  // Function to convert any currency amount to KWD using exchange rates
  const convertToKWD = (amount: number, currency: string) => {
    if (currency !== "KWD") {
      const rate = parseFloat(configContext.exchange_rates?.rates[currency] ?? '1');
      return amount * rate;
    }
    return amount;
  };

  // Calculate values for each fund, converting currencies to KWD as necessary
  const calculateFundValues = (fundInPosition: CustomerFundData) => {
    const fund = fundByCode[fundInPosition.gl_code ?? ''];
    const isOpenEnded = fund?.type === 'open_ended';

    const nav = isOpenEnded ? fund?.nav_per_unit ?? 0 : 0;
    const units = fundInPosition.eunits ?? 0;
    const totalSubscriptions = fundInPosition.total_subscriptions ?? 0;

    // Calculate total value in KWD
    const fundTotalKWD = isOpenEnded ? units * nav : totalSubscriptions;

    const currency = fundInPosition.currency ?? 'KWD';

    // Return calculated values for both distributions and YTD distributions
    return {
      totalKWD: convertToKWD(fundTotalKWD, currency),
      totalDividendsInKWD: convertToKWD((fundInPosition.total_redemptions?.pnl ?? 0), currency), // PNL
      totalReturnOnCapitalInKWD: convertToKWD((fundInPosition.total_redemptions?.roc ?? 0), currency), // ROC
      totalYTDDividendsInKWD: convertToKWD((fundInPosition.ytd_redemptions?.pnl ?? 0), currency), // YTD PNL
      totalYTDReturnOnCapitalInKWD: convertToKWD((fundInPosition.ytd_redemptions?.roc ?? 0), currency), // YTD ROC
    };
  };

  // Iterate over each fund in fundData and calculate totals for each
  if (fundData.length > 0) {
    fundData.forEach(fundInPosition => {
      const { totalKWD, totalDividendsInKWD, totalReturnOnCapitalInKWD, totalYTDDividendsInKWD, totalYTDReturnOnCapitalInKWD } = calculateFundValues(fundInPosition);

      const code = fundInPosition.gl_code ?? '';
      fundsTotalInKWD[code] = totalKWD;
      fundsDividendsInKWD[code] = totalDividendsInKWD; // PNL
      fundsReturnOnCapitalInKWD[code] = totalReturnOnCapitalInKWD; // ROC
      fundsYTDDividendsInKWD[code] = totalYTDDividendsInKWD; // YTD PNL
      fundsYTDReturnOnCapitalInKWD[code] = totalYTDReturnOnCapitalInKWD; // YTD ROC
    });

    // Calculate grand totals
    totalValue = Object.values(fundsTotalInKWD).reduce((sum, value) => sum + value, 0);
    totalDividends = Object.values(fundsDividendsInKWD).reduce((sum, value) => sum + value, 0);
    totalReturnOnCapital = Object.values(fundsReturnOnCapitalInKWD).reduce((sum, value) => sum + value, 0);
    totalYTDDividends = Object.values(fundsYTDDividendsInKWD).reduce((sum, value) => sum + value, 0);
    totalYTDReturnOnCapital = Object.values(fundsYTDReturnOnCapitalInKWD).reduce((sum, value) => sum + value, 0);
    let counter = 0;

    fundData.forEach(fundInPosition => {
      const code = fundInPosition.gl_code ?? '';
      const name = fundInPosition.gl_name ?? '';
      let percentage = (fundsTotalInKWD[code] / totalValue) * 100;
      let color = counter < colors.length ? colors[counter] : undefined;
      percentage = parseFloat(percentage.toFixed(2))

      pieData.push(
        {
          id: counter,
          value: percentage,
          label: (location) => location == 'legend' ? name + ' (' + percentage + '%)' : name,
          ...(color && { color }),
        }
      );
      counter++;
    });
  }


  // Render the table with fund data and totals
  return (
    <>
      <Title fz={'h3'} ta={'center'}>Fund Allocation Breakdown</Title>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><FundsAllocationPieChart pieData={pieData} totalValue={totalValue} /></div>
      <br />
      <Table striped withTableBorder withColumnBorders ta="center">
        <Table.Thead>
          <Table.Tr>
            <Table.Th ta="center"></Table.Th>
            <Table.Th ta="center">Fund Name</Table.Th>
            <Table.Th ta="center">Units</Table.Th>
            <Table.Th ta="center">{type == "open_ended" ? "" : "Initial "}Value (Fund Currency)</Table.Th>
            <Table.Th ta="center">{type == "open_ended" ? "" : "Initial "}Value (KWD)</Table.Th>
            <Table.Th ta="center">% of Total {type == "open_ended" ? "" : "Initial "} Value</Table.Th>
            <Table.Th ta="center">YTD Distributions</Table.Th>
            <Table.Th ta="center">Total Distributions</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {fundData.map(fundInPosition => {
            const code = fundInPosition.gl_code ?? '';
            const { totalKWD, totalDividendsInKWD, totalReturnOnCapitalInKWD, totalYTDDividendsInKWD, totalYTDReturnOnCapitalInKWD } = calculateFundValues(fundInPosition);

            const shares = Math.round(fundInPosition.eunits ?? 0);
            const amount = fundByCode[code]?.type === 'open_ended' ? (fundInPosition.eunits ?? 0) * (fundByCode[code]?.nav_per_unit ?? 0) : fundInPosition.total_subscriptions ?? 0;
            const totalYTDDividends = fundInPosition.ytd_redemptions?.pnl ?? 0;
            const totalYTDReturnOnCapital = fundInPosition.ytd_redemptions?.roc ?? 0;
            const totalDividends = fundInPosition.total_redemptions?.pnl ?? 0;
            const totalReturnOnCapital = fundInPosition.total_redemptions?.roc ?? 0;
            const totalDistributions = fundInPosition.total_redemptions?.total ?? 0;
            const totalYTDDistributions = fundInPosition.ytd_redemptions?.total ?? 0;
            let fundPercentage = (totalKWD / totalValue) * 100;
            fundPercentage = parseFloat(fundPercentage.toFixed(2))
            let transactions: CustomerFundDataTransaction[] = []
            if (fundInPosition.transactions) {
              Object.entries(fundInPosition.transactions ?? {}).forEach(([key, transactionRow]) => {
                if (transactionRow.currency !== "KWD" && transactionRow.currency) {
                  let rate = parseFloat(configContext.exchange_rates?.rates[transactionRow.currency] ?? 0);
                  transactionRow.amountInKWD = ((transactionRow.amount ?? 0) * rate);
                } else {
                  transactionRow.amountInKWD = transactionRow.amount;
                }
                transactions.push(transactionRow);
              });
              transactions = transactions.sort((a, b) => (b.deal_date?.toMillis() ?? 0) - (a.deal_date?.toMillis() ?? 0));
            }

            return (<>
              <Table.Tr key={code}>
                <Table.Td>
                  <IconChevronDown className='icon-hover' style={{ transition: '0.2s', rotate: openedCode == code ? '180deg' : '0deg' }} onClick={() => (openedCode == code ? setOpenedCode(null) : setOpenedCode(code))} />
                </Table.Td>

                <Table.Td>
                  {code && fundByCode[code] ? (
                    <Tooltip transitionProps={{ transition: 'pop', duration: 200 }} label={<FundSummarizedData fund={fundByCode[code]} />}>
                      <span>{fundInPosition.gl_name}</span>
                    </Tooltip>
                  ) : (
                    <span>{fundInPosition.gl_name}</span>
                  )}
                </Table.Td>
                <Table.Td ><NumberDisplay value={shares} withCommas /></Table.Td>
                <Table.Td><NumberDisplay value={amount} withCommas currency={fundInPosition.currency ?? 'KWD'} /></Table.Td>
                <Table.Td><NumberDisplay value={totalKWD} withCommas currency="KWD" /></Table.Td>
                <Table.Td>
                  <NumberDisplay value={fundPercentage} withCommas withPercent />

                </Table.Td>
                {/* YTD Distributions Tooltip */}
                <Table.Td>
                  <DistributionData pnl={totalYTDDividends} roc={totalYTDReturnOnCapital} total={totalYTDDistributions} currency={fundInPosition.currency ?? 'KWD'} />
                </Table.Td>

                {/* Total Distributions Tooltip */}
                <Table.Td>
                  <DistributionData pnl={totalDividends} roc={totalReturnOnCapital} total={totalDistributions} currency={fundInPosition.currency ?? 'KWD'} />
                </Table.Td>

              </Table.Tr>

              <Table.Tr display={'none'}></Table.Tr>
              <Table.Tr key={code + 'Transactions'} style={{ height: 0, padding: 0, border: 0 }} >
                <Table.Td colSpan={8} style={{ height: 0, padding: 0, border: 0 }} >
                  <Collapse in={openedCode == code} transitionDuration={200}>
                    <Card style={{ width: '100%' }}>
                      <FundTransactions transactions={transactions} />
                    </Card>
                  </Collapse>
                </Table.Td>
              </Table.Tr>
            </>
            );
          })}
          {/* <Table.Tr key="total" >
            <Table.Td colSpan={4}><Text size="sm" fw="bold">Total</Text></Table.Td>
            <Table.Td><NumberDisplay value={totalValue} withCommas currency="KWD" /></Table.Td>
            <Table.Td>
              <NumberDisplay value={100} withCommas withPercent />
            </Table.Td>

            <Table.Td>
              -
            </Table.Td>

            <Table.Td>
              -
            </Table.Td>

          </Table.Tr> */}
        </Table.Tbody>
      </Table>
    </>
  );
};

/**
 * DistributionBreakdown component shows the breakdown of Dividends (PNL) and Return on Capital (ROC) in a tooltip.
 * Props:
 * - pnl: Amount of Dividends (PNL) in KWD.
 * - roc: Amount of Return on Capital (ROC) in KWD.
 * - currency: The currency of the values displayed.
 */
const DistributionData = ({ pnl, roc, total, currency }: { pnl: number, roc: number, total: number, currency: string }) => (
  <>{pnl === 0 && roc === 0
    ? <span>-</span>
    : (
      <Tooltip transitionProps={{ transition: 'pop', duration: 200 }} label={<DistributionBreakdown pnl={pnl} roc={roc} currency={currency} />}>
        <span><NumberDisplay value={total} withCommas currency={currency} /></span>
      </Tooltip>
    )
  }
  </>
);
const DistributionBreakdown = ({ pnl, roc, currency }: { pnl: number, roc: number, currency: string }) => (
  <Table withTableBorder={false} withColumnBorders={false} withRowBorders={false}>
    <Table.Tr>
      <Table.Td><strong>Dividends</strong></Table.Td>
      <Table.Td ta={'center'}>{<NumberDisplay value={pnl} withCommas currency={currency} customColor='white' />}</Table.Td>
    </Table.Tr>
    <Table.Tr>
      <Table.Td><strong>Return of capital</strong></Table.Td>
      <Table.Td ta={'center'}>{<NumberDisplay value={roc} withCommas currency={currency} customColor='white' />}</Table.Td>
    </Table.Tr>
  </Table>
);


// Function to calculate portfolio percentages
const calculatePortfolioPercentages = (items: Position[]): Position[] => {
  const totalMarketValue = items.reduce((acc, item) => acc + (item.total_market_value ?? 0), 0);
  return items.map((item) => ({
    ...item,
    portfolio_percent: totalMarketValue === 0 ? 0 : ((item.total_market_value ?? 0) / totalMarketValue) * 100
  }));
};

// Function to group positions by exchange and currency
const groupPositionsByExchangeAndCurrency = (positions: Position[]): GroupedPosition[] => {
  const groupedMap = new Map<string, GroupedPosition>();

  for (const position of positions) {
    const key = `${position.exchange}-${position.currency}`;

    if (!groupedMap.has(key)) {
      groupedMap.set(key, {
        exchange: position.exchange ?? "",
        currency: position.currency ?? "",
        title: `${position.exchange} (${position.currency})`,
        list: [position],
        combinedPosition: { ...position }
      });
    } else {
      const groupedPosition = groupedMap.get(key)!;
      groupedPosition.list.push(position);
      combinePositions(groupedPosition.combinedPosition, position);
    }
  }

  return Array.from(groupedMap.values());
};

// Function to combine positions for summary
const combinePositions = (combined: Position, position: Position) => {
  combined.total_cost = (combined.total_cost ?? 0) + (position.total_cost ?? 0);
  combined.total_market_value = (combined.total_market_value ?? 0) + (position.total_market_value ?? 0);
  combined.unrealized_gain_loss = (combined.unrealized_gain_loss ?? 0) + (position.unrealized_gain_loss ?? 0);
  combined.gain_loss_percent = (combined.gain_loss_percent ?? 0) + (position.gain_loss_percent ?? 0);
  combined.total_cost_year_close_local = (combined.total_cost_year_close_local ?? 0) + (position.total_cost_year_close_local ?? 0);
  combined.total_market_value_local = (combined.total_market_value_local ?? 0) + (position.total_market_value_local ?? 0);
  combined.unrealized_fx = (combined.unrealized_fx ?? 0) + (position.unrealized_fx ?? 0);
  combined.unrealized_equity = (combined.unrealized_equity ?? 0) + (position.unrealized_equity ?? 0);
  combined.total_unrealized_gain_loss_local = (combined.total_unrealized_gain_loss_local ?? 0) + (position.total_unrealized_gain_loss_local ?? 0);
  combined.portfolio_percent = (combined.portfolio_percent ?? 0) + (position.portfolio_percent ?? 0);
};

// Functions to get various details for portfolio info cards
const getPortfolioDetailsList = (portfolio: Portfolio) => [
  { label: "PF Name", value: portfolio.investmentPortfolio },
  { label: "PF Number", value: portfolio.code },
  { label: "Opening Date", value: portfolio.position?.created },
  { label: "Managed By", value: portfolio.position?.managed_by },
  { label: "PF Manager", value: portfolio.position?.manager },
  { label: "PF Type", value: portfolio.position?.type },
  { label: "Mng Fees %", value: `${portfolio.position?.fees} %` },
  { label: "PF Status", value: portfolio.position?.status },
  { label: "Investment Portfolio", value: portfolio.investmentPortfolio },
  { label: "Portfolio Type", value: portfolio.portfolioType }
];

const getPositionFinancialsList = (portfolio: Portfolio) => [
  { label: "Cash", value: <NumberDisplay value={portfolio.position?.cash} withCommas /> },
  { label: "Securities", value: <NumberDisplay value={portfolio.position?.securities} withCommas /> },
  { label: "Dividend Due", value: <NumberDisplay value={portfolio.position?.dividend_due} withCommas /> },
  { label: "Sukuk Profit Receivable", value: <NumberDisplay value={portfolio.position?.sukuk_profit_receivable} withCommas /> },
  { label: "Other Receivables", value: portfolio.position?.type },
  { label: "Other Liabilities", value: <NumberDisplay value={portfolio.position?.other_liabilities} withCommas withColor withBrackets /> },
  { label: "Total Capital Market Value", value: <NumberDisplay value={portfolio.position?.total_capital_market_value} withCommas /> },
  { label: "Cash %", value: <NumberDisplay value={portfolio.position?.cash_percent} withCommas withPercent /> }
];

const getPerformanceMetricsList = (portfolio: Portfolio) => [
  { label: "Realized G/L YTD", value: <NumberDisplay value={portfolio.position?.realized_gain_loss_ytd} withCommas /> },
  { label: "Cash Dividend YTD", value: <NumberDisplay value={portfolio.position?.cash_dividend_ytd} withCommas /> },
  { label: "Unrealized P/L", value: <NumberDisplay value={portfolio.position?.unrealized_profit_loss} withCommas withBrackets withColor /> },
  { label: "Other Income", value: <NumberDisplay value={portfolio.position?.other_income} withCommas /> },
  { label: "Management Fee", value: <NumberDisplay value={portfolio.position?.management_fee} withCommas withBrackets withColor /> },
  { label: "Other Expenses", value: <NumberDisplay value={portfolio.position?.other_expenses} withCommas withColor withBrackets /> },
  { label: "Net Income", value: <NumberDisplay value={portfolio.position?.net_income} withCommas withBrackets withColor /> },
  { label: "Performance", value: <NumberDisplay value={portfolio.position?.performance} withCommas withPercent withColor /> }
];

const FundsAllocationPieChart = ({ pieData, totalValue }: { pieData: PieValueType[], totalValue: number }) => (
  <PieChart
    series={[
      {
        data: pieData,
        innerRadius: 100,
        highlightScope: { fade: 'global', highlight: 'item' },
        faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
        valueFormatter: (item: { value: number }) => `${item.value}%`,


      },
    ]}
    slotProps={{
      legend: {
        direction: 'column',
        position: { vertical: 'middle', horizontal: 'right' },
        padding: 0,
      },

    }}
    margin={{ top: 20, right: 300 }}
    width={800}
    height={300}
  >
    <PieCenterLabel>
      {toKwdInt(totalValue)}
    </PieCenterLabel>

  </PieChart>
);

const FundTransactions = ({ transactions }: { transactions: CustomerFundDataTransaction[] }) => (
  <>
    {transactions.length > 0 && <Table striped withTableBorder withColumnBorders ta="center" >
      <Table.Tbody>
        <Table.Tr key={transactions[0].code + 'Transactions'} bg={'#f8f9fa'} >
          <Table.Th ta="center">Transaction Date</Table.Th>
          <Table.Th ta="center">Type</Table.Th>
          <Table.Th ta="center">Description</Table.Th>
          <Table.Th ta="center">Amount</Table.Th>
          <Table.Th ta="center">Units</Table.Th>
          {/* <Table.Th ta="center">Amount (KWD)</Table.Th> */}
        </Table.Tr>
        {transactions.map(transaction => {
          //set the type and icon based on the type in fund_data where Subscriptions mean Outgoing and Redemptions mean Incoming
          //outgoing only mean subscription
          //incoming mean redemption, distributions or return on capitals
          let type = (transaction.type === "subscription" ? 'Outgoing' : transaction.type === "redemption" ? 'Incoming' : transaction.type) ?? '-';
          //capitalize the type
          type = type.charAt(0).toUpperCase() + type.slice(1);

          const icon = type === 'Outgoing' ? <IconArrowUp size={16} /> : type === 'Incoming' ? <IconArrowDown size={16} /> : undefined;
          let desc = transaction.description ?? '-'
          if (desc.toLocaleLowerCase() === 'pnl') {
            desc = 'Dividends';
          } else if (desc.toLocaleLowerCase() === 'roc') {
            desc = 'Return of Capital';
          }
          else {
            desc = "-";
          }

          let amount = transaction.user_amount || transaction.amount || 0;
          let shares = transaction.user_shares || transaction.shares || 0;

          return <Table.Tr >
            <Table.Td>{transaction.deal_date?.toDate().toLocaleDateString("en-gb") ?? "-"}</Table.Td>
            <Table.Td>{type} {icon}</Table.Td>
            <Table.Td>{desc}</Table.Td>
            <Table.Td><NumberDisplay value={amount} withCommas currency={transaction.currency} /> </Table.Td>
            <Table.Td><NumberDisplay value={shares} withCommas /> </Table.Td>
            {/* <Table.Td><NumberDisplay value={transaction.amountInKWD} withCommas currency='KWD' /> </Table.Td> */}
          </Table.Tr>
        })}
      </Table.Tbody>
    </Table>}
    {transactions.length <= 0 &&
      <Text>No Transaction Found</Text>
    }
  </>
);

const StyledText = styled('text')(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: 'middle',
  dominantBaseline: 'central',
}));

function PieCenterLabel({ children }: { children: React.ReactNode }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={left + width / 2} y={top + height / 2}>
      {children}
    </StyledText>
  );
}



