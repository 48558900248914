import { Loader, SimpleGrid } from "@mantine/core";
import { collection, query, where } from "firebase/firestore";
import { useFirestore } from "../helpers/firebaseContext";
import useFirestoreCollection from "../helpers/useFirestoreCollection";
import { Fund } from "../types";
import FundCard from "./fund_card";

export default function OpenEndedSummary() {

    const db = useFirestore();

    const fundsDataQuery = query(collection(db, "funds"), where("active", "==", true), where("type", "==", "open_ended"));
    const { data: fundsData, loading: loading } = useFirestoreCollection(fundsDataQuery);

    if (loading || !fundsData) {
        return <Loader />
    }

    return (
        <>
            <div style={{ width: '100%' }}>
                <SimpleGrid cols={{ base: 3 }}>
                    {(fundsData as Fund[]).map(fundData =>
                        <FundCard key={fundData.id}
                            fund={fundData}
                        />
                    )}
                </SimpleGrid>
            </div>
        </>
    );
}
