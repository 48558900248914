import { Button, Grid, Text, Textarea, Title, Select, Group, useMantineTheme, useMantineColorScheme, SimpleGrid, Image, ActionIcon } from '@mantine/core'
import { useForm } from '@mantine/form';
import { useContext, useState } from 'react';
import { FeedbackRequest, FeedbackType } from '../../types';
import { useNavigate } from 'react-router-dom';
import CustomModal from '../../components/custom_modal';
import axios from 'axios';
import { AppConfigContext } from '../../contexts';
import { auth } from '../../helpers/firebaseContext';
import { Dropzone, FileWithPath } from '@mantine/dropzone';
import { IconPhoto, IconUpload, IconX } from '@tabler/icons-react';

const CreateFeedback = () => {

    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
    const [errMsg, setErrMsg] = useState<string>();
    const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState<boolean>(false);
    const [files, setFiles] = useState<FileWithPath[]>([]);
    const { apiUrl } = useContext(AppConfigContext);
    const theme = useMantineTheme();
    const { colorScheme } = useMantineColorScheme();

    const navigate = useNavigate();

    const form = useForm<
        FeedbackRequest
    >({
        initialValues: {
            body: "",
            type: FeedbackType.complaint,
        },
    });

    const previews = files.map((file, index) => {
        const imageUrl = URL.createObjectURL(file);
        return <Image key={index} src={imageUrl} onLoad={() => URL.revokeObjectURL(imageUrl)} />;
    });

    const handleSubmit = async (values: FeedbackRequest) => {
        setErrMsg(undefined);

        if (values.body.length <= 0) {
            setErrMsg("Description cannot be empty");
            return;
        }
        if (values.type.length <= 0) {
            setErrMsg("Type cannot be empty");
            return;
        }

        setLoadingSubmit(true);
        try {
            const token = await auth.currentUser?.getIdToken();
            const formData = new FormData();
            // formData.append('taskName', values.taskName);
            formData.append('feedbackBody', values.body);
            formData.append('feedbackType', values.type);
            // if (file) {
            //     formData.append('file', file);
            // }
            if (files && files.length > 0) {
                formData.append('file', files[0]);

            }

            const response = await axios.post(apiUrl + '/submitFeedback', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + token,
                }
            });

            setLoadingSubmit(false);
            if (response.status != 200) {
                setErrMsg(response.data.message ?? 'Server Error');
                return;
            } else {
                setIsSubmittedSuccessfully(true);
                return;
            }

        } catch (e: any) {
            setErrMsg(e?.message || "An unexpected error has occurred");
            setLoadingSubmit(false);
        }
    };

    return (
        <Grid justify='center' bg={"white"} h="100%">
            <Grid.Col span={{ xs: 12 }}>

                <Title
                    ms={30}
                    mt={30}
                    mb={20}
                    order={4}
                    ta="left">Create Feedback</Title>
            </Grid.Col>

            <Grid.Col span={{ lg: 8 }}>

                <form onSubmit={form.onSubmit(handleSubmit)}>
                    <Grid>

                        <Grid.Col span={{ xs: 12 }}>
                            <Textarea
                                rows={5}
                                label="Description"
                                required
                                placeholder=''
                                m={10}
                                {...form.getInputProps('description')}
                                value={form.values.body}
                                error={form.errors.body}
                                onChange={(e) => {
                                    form.setFieldValue("body", e.target.value)
                                }}
                            />
                        </Grid.Col>

                        <Grid.Col span={{ xs: 12 }}>
                            <Select
                                label="Type"
                                required
                                placeholder='Select feedback type'
                                m={10}
                                data={[
                                    { value: FeedbackType.complaint, label: 'Complaint' },
                                    { value: FeedbackType.suggestion, label: 'Suggestion' },
                                    { value: FeedbackType.bug, label: 'Bug' },
                                    { value: FeedbackType.other, label: 'Other' },
                                ]}
                                {...form.getInputProps('type')}
                            />
                        </Grid.Col>

                        <Grid.Col span={{ xs: 12 }}>
                            <Dropzone accept={["image/png", "image/jpeg"]} onDrop={setFiles} multiple={false}>
                                <Group justify="start" gap="xl" style={{ minHeight: 220, pointerEvents: 'none' }}>
                                    <Dropzone.Accept>
                                        <IconUpload
                                            size={50}
                                            stroke={1.5}
                                            color={theme.colors[theme.primaryColor][colorScheme === 'dark' ? 4 : 6]}
                                        />
                                    </Dropzone.Accept>
                                    <Dropzone.Reject>
                                        <IconX
                                            size={50}
                                            stroke={1.5}
                                            color={theme.colors.red[colorScheme === 'dark' ? 4 : 6]}
                                        />
                                    </Dropzone.Reject>
                                    <Dropzone.Idle>
                                        <IconPhoto size={50} stroke={1.5} /> "Drag images here or click to select files"
                                    </Dropzone.Idle>


                                </Group>

                            </Dropzone>

                            <SimpleGrid cols={{ base: 1, sm: 4 }} mt={previews.length > 0 ? 'xl' : 0}>
                                {previews}
                                {previews.length > 0 && <ActionIcon size={'xs'} variant="transparent" color='dark' onClick={() => {
                                    setFiles([]);
                                }}>
                                    <IconX />
                                </ActionIcon>}

                            </SimpleGrid>
                        </Grid.Col>

                        <Grid.Col span={{ xs: 12 }} >
                            <Text c={"red"}>
                                {errMsg}
                            </Text>
                        </Grid.Col>

                        <Grid.Col span={{ xs: 3 }} >
                            <Button
                                loading={loadingSubmit}
                                color='green'
                                type='submit'
                                fullWidth
                            >
                                Send
                            </Button>
                        </Grid.Col>
                    </Grid>
                    {isSubmittedSuccessfully && <CustomModal
                        title="Feedback Sent Successfully"
                        withCloseButton={false}
                        overlayProps={{
                            backgroundOpacity: 0.55,
                            blur: 10,
                        }}
                        opened={isSubmittedSuccessfully}
                        centered
                    >
                        <Grid >
                            <Grid.Col span={12}>
                                <Button fullWidth mt={5}
                                    onClick={() => navigate("/", {
                                    })}
                                    variant='outline'
                                    color={"blue"}
                                >
                                    Go Home
                                </Button>
                            </Grid.Col>

                        </Grid>
                    </CustomModal>}
                </form>
            </Grid.Col>

        </Grid >
    )
}

export default CreateFeedback