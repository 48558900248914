import { Grid, Group, Space, Tabs, Title, } from '@mantine/core'
import React, { useContext } from 'react'

import RiskCountriesSettings from './risk_countries_settings';
import ApproversSettings from './approvers_settings';
import CustomClaimsSettings from './custom_claims_settings';

import NavUpdateSettings from './nav_update';
import RefreshDataSettings from './refresh_data_settings';
import { MyUserDataContext } from '../../contexts';
import { CustomClaims } from '../../types';
import FirebaseGatewaySettings from './firebase_gateway';

const Settings = () => {
    const [activeTab, setActiveTab] = React.useState<string | null>("nav_settings");

    //get claims
    const userContext = useContext(MyUserDataContext);
    let userClaims = userContext.firebaseTokenResult?.claims.customClaims as CustomClaims || {};


    return (
        <main style={{ padding: "1rem 0" }}>


            <Group justify="space-between">
                <Title>Update Settings</Title>
            </Group>

            <Space h="xl" />

            <Grid gutter="md">

                <Grid.Col span={{ xs: 12 }}>
                    <Tabs onChange={setActiveTab} value={activeTab}>
                        <Tabs.List>
                            <Tabs.Tab disabled={!userClaims.admin && !userClaims.oper} value="nav_settings">Nav Settings</Tabs.Tab>
                            <Tabs.Tab disabled={!userClaims.cr && !userClaims.risk && !userClaims.admin && !userClaims.sys} value="risk_countries">Risk Countries</Tabs.Tab>
                            <Tabs.Tab disabled={!userClaims.admin && !userClaims.sys} value="approve_settings">Approval settings</Tabs.Tab>
                            <Tabs.Tab disabled={!userClaims.admin && !userClaims.sys} value="claims_settings">Claims settings</Tabs.Tab>
                            <Tabs.Tab disabled={!userClaims.admin} value="data_settings">Data settings</Tabs.Tab>
                            <Tabs.Tab disabled={!userClaims.admin} value="firebase_gateway">Firebase Gateway</Tabs.Tab>
                        </Tabs.List>

                        <Space h="xl" />


                        <Tabs.Panel value="nav_settings">
                            <NavUpdateSettings />


                        </Tabs.Panel >
                        <Tabs.Panel value="risk_countries">
                            <RiskCountriesSettings />


                        </Tabs.Panel >
                        {/* <Tabs.Panel value="risk_countries">
                        <NavUpdateSettings />

                    </Tabs.Panel > */}
                        <Tabs.Panel value="approve_settings">
                            <ApproversSettings />


                        </Tabs.Panel >

                        <Tabs.Panel value="claims_settings">
                            <CustomClaimsSettings />
                        </Tabs.Panel >
                        <Tabs.Panel value='data_settings'>
                            <RefreshDataSettings />
                        </Tabs.Panel>

                        <Tabs.Panel value='firebase_gateway'>
                            <FirebaseGatewaySettings />
                        </Tabs.Panel>

                    </Tabs >
                </Grid.Col>










            </Grid>


        </main>
    )
}

export default Settings