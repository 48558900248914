import { ActionIcon, Card, Checkbox, Divider, Grid, Input, Loader, Select, Text, Textarea, TextInput } from '@mantine/core'
import { useContext, useEffect, useState } from 'react'
import { ConfigDataType, CreateCampaignRequest, Fund, FundAutoComplete, TwilioContentType } from '../../types';
import { UseFormReturnType } from '@mantine/form';
import { refreshEmailTemplates, refreshWhatsappTemplates } from '../../components/send_sms_modal';
import { AppConfigContext, ConfigContext } from '../../contexts';
import { IconRefresh } from '@tabler/icons-react';
import { replaceText } from '../../helpers/common';
import { transformFundsToGroupedData } from '../add_order';



const BasicCampaignForm = ({ form, funds }: { funds: FundAutoComplete[], form: UseFormReturnType<CreateCampaignRequest, (values: CreateCampaignRequest) => CreateCampaignRequest> }) => {
    const [whatsappTemplates, setWhatsappTemplates] = useState<TwilioContentType[]>([]);
    const [emailTemplates, setEmailTemplates] = useState<any[]>([]);
    const [loadingEmailTemplates, setLoadingEmailTemplates] = useState<boolean>(false);
    const [loadingWhatsappTemplates, setLoadingWhatsappTemplates] = useState<boolean>(false);
    const [smsSampleText, setSMSSampleText] = useState("");
    const [whatsappSampleText, setWhatsappSampleText] = useState("");
    const [emailSampleText, setEmailSampleText] = useState("");
    const [selectedNotificationDataType, setSelectedNotificationDataType] = useState<string>("none");
    const [chosenFund, setChosenFund] = useState<Fund | null>(null);
    const [notificationSampleText, setNotificationSampleText] = useState("");
    const [selectedFund, setSelectedFund] = useState<Fund | null>(null);

    //debug here , not showing context/apiUrl in modal
    const context = useContext(AppConfigContext);
    const config: ConfigDataType = useContext(ConfigContext);
    const { apiUrl } = context;



    const sampleObj = {
        name_en: "Yousef",
        name_ar: "يوسف",
        date: new Date().toLocaleDateString(),
        // fundName: chosenFund ? chosenFund.name_en : "Fund"
    };
    const MessageTextchanged = (text: string, stateFunction: (value: string) => void) => {

        let s = { ...sampleObj, ...form.values.messageVariables };
        //ok figure out sample text stuff processing
        stateFunction(replaceText(text, s));
    };



    useEffect(() => {

        if (funds?.length === 1) {
            setChosenFund(funds[0])
        }

    }, [funds]);



    useEffect(() => {
        refreshWhatsappTemplates(true, form, apiUrl!, setWhatsappTemplates, false, setLoadingWhatsappTemplates,
            // (chosenFund && config?.factsheetTemplates) ? config.factsheetTemplates[chosenFund?.type].whatsappFactsheetTemplate : undefined
        )
        refreshEmailTemplates(true, form, apiUrl!, setEmailTemplates, setLoadingEmailTemplates,
            // (chosenFund && config?.factsheetTemplates) ? config.factsheetTemplates[chosenFund?.type].emailFactsheetTemplate : undefined
        )



        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        if (chosenFund && config.factsheetTemplates && config.factsheetTemplates[chosenFund.type]) {

            form.setFieldValue("selectedEmailTemplate", config?.factsheetTemplates[chosenFund.type]?.emailFactsheetTemplate)
            form.setFieldValue("selectedWhatsappTemplate", whatsappTemplates.find((template) => template.sid === config.factsheetTemplates![chosenFund.type]?.whatsappFactsheetTemplate))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [whatsappTemplates, emailTemplates]);


    useEffect(() => {
        const templateValue = form.values.selectedWhatsappTemplate
        if (templateValue) {

            MessageTextchanged((templateValue?.types["twilio/text"] || templateValue?.types["twilio/media"]) ?
                (templateValue?.types["twilio/text"]?.body || templateValue?.types["twilio/media"]?.body) :
                templateValue?.types["twilio/call-to-action"]?.body, setWhatsappSampleText)

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.values.selectedWhatsappTemplate])


    return (
        <Grid>


            <Grid.Col span={{ xs: 12 }} >
                <Checkbox.Group
                    // name="type"
                    label="Select message type"
                    // description="This is anonymous"
                    withAsterisk
                    color="green"
                    defaultValue={chosenFund ? ["whatsapp", "email"] : []}
                    value={form.values.type}
                    onChange={(value) => {

                        const chosenTypes = value as ("sms" | "email" | "customEmail" | "whatsapp" | "notification")[]
                        if (!form.values.type?.includes("whatsapp") && chosenTypes?.includes("whatsapp")) {
                            refreshWhatsappTemplates(true, form, apiUrl!, setWhatsappTemplates, false, setLoadingWhatsappTemplates,

                                // (chosenFund && config?.factsheetTemplates) ? config.factsheetTemplates[chosenFund?.type].whatsappFactsheetTemplate : undefined

                            )
                        }
                        if (!form.values.type?.includes("email") && chosenTypes?.includes("email")) {
                            refreshEmailTemplates(true, form, apiUrl!, setEmailTemplates, setLoadingEmailTemplates,

                                // (chosenFund && config?.factsheetTemplates) ? config.factsheetTemplates[chosenFund?.type].emailFactsheetTemplate : undefined
                            )
                        }
                        form.setFieldValue("type", chosenTypes)

                        if (!chosenTypes?.includes("email")) {
                            form.setFieldValue("subject", undefined)

                        }
                    }}
                >
                    <Grid align='start' mt={20} >

                        <Grid.Col span={{ xs: 6 }}>
                            <Card shadow="sm" padding="lg" radius="md" withBorder>
                                <Checkbox
                                    color="green"
                                    styles={{
                                        input: { marginTop: 6 },
                                        icon: { marginTop: 11 },
                                    }}
                                    value="email"
                                    // label="Email"
                                    // checked={form.values.type?.includes("email")}
                                    label={
                                        <Grid justify='center' align='center'>

                                            <Grid.Col span={9}>

                                                Email
                                            </Grid.Col>


                                        </Grid>
                                    }

                                />


                                {form.values.type?.includes("email") &&
                                    <Grid justify='center' align='center' mt={20}>


                                        <Grid.Col span={{ xs: 8 }} >
                                            {loadingEmailTemplates ?
                                                <Loader size={30} />
                                                : <Select
                                                    label="Select Email template"
                                                    value={form.values.selectedEmailTemplate}
                                                    defaultValue={
                                                        (chosenFund && config?.factsheetTemplates) ? config.factsheetTemplates[chosenFund?.type].emailFactsheetTemplate : form.values.selectedEmailTemplate

                                                    }
                                                    onChange={(value: string | null, option: any) => {

                                                        if (value)
                                                            form.setFieldValue("selectedEmailTemplate", value)
                                                        else form.setFieldValue("selectedEmailTemplate", undefined)
                                                    }}
                                                    data={emailTemplates.map((template: any) => {
                                                        return {
                                                            ...template,
                                                            value: template.id,
                                                            label: template?.name
                                                        }
                                                    })} />}

                                        </Grid.Col>
                                        <Grid.Col span={3} mt={25}>

                                            <ActionIcon
                                                disabled={loadingEmailTemplates}
                                                onClick={async () => await refreshEmailTemplates(form.values.type?.includes("email"), form, apiUrl!, setEmailTemplates, setLoadingEmailTemplates,

                                                    // (chosenFund && config?.factsheetTemplates) ? config.factsheetTemplates[chosenFund?.type].emailFactsheetTemplate : undefined
                                                )

                                                }
                                                variant="subtle" aria-label="refres-templates">

                                                <IconRefresh stroke={1.5} />
                                            </ActionIcon>
                                        </Grid.Col>
                                    </Grid>

                                }

                            </Card>
                        </Grid.Col>
                        <Grid.Col span={{ xs: 6 }}>
                            <Card shadow="sm" padding="lg" radius="md" withBorder>
                                <Checkbox
                                    value="whatsapp"
                                    color="green"
                                    styles={{
                                        input: { marginTop: 6 },
                                        icon: { marginTop: 11 },
                                    }}
                                    // checked={form.values.type?.includes("whatsapp")}
                                    label={
                                        <Grid justify='center' align='center'>
                                            <Grid.Col span={9}>
                                                Whatsapp
                                            </Grid.Col>
                                        </Grid>
                                    }
                                />
                                {form.values.type?.includes("whatsapp") &&

                                    <Grid justify='center' align='start' mt={20}>

                                        <Grid.Col span={{ xs: 8 }} >
                                            {loadingWhatsappTemplates ?
                                                <Loader size={30} />
                                                : <>
                                                    <Select
                                                        label="Select whatsapp  template"
                                                        // value={selectedWhatsappTemplate?.value}
                                                        value={form.values.selectedWhatsappTemplate?.sid}
                                                        defaultValue={

                                                            (chosenFund && config?.factsheetTemplates) ? config.factsheetTemplates[chosenFund?.type].whatsappFactsheetTemplate : form.values.selectedWhatsappTemplate?.sid

                                                        }
                                                        onChange={(value, option: any) => {
                                                            // setSelectedWhatsappTemplate(option)
                                                            form.setFieldValue("selectedWhatsappTemplate", option)

                                                        }}
                                                        data={whatsappTemplates?.map((template: TwilioContentType) => {
                                                            return {
                                                                ...template,
                                                                value: template.sid,
                                                                label: template?.friendly_name
                                                            }
                                                        })}
                                                    />
                                                    <>
                                                        <p>
                                                            <Text size="xs">SAMPLE</Text>
                                                        </p>
                                                        <p>
                                                            <Text c="blue" dir={form.getInputProps("language").value === "Arabic" ? "rtl" : "ltr"}>

                                                                {whatsappSampleText}
                                                            </Text>
                                                        </p>

                                                    </>
                                                </>
                                            }
                                        </Grid.Col>
                                        <Grid.Col span={3} mt={25}>

                                            <ActionIcon
                                                disabled={loadingWhatsappTemplates}
                                                onClick={async () => await refreshWhatsappTemplates(form.values.type?.includes("whatsapp"), form, apiUrl!, setWhatsappTemplates, false, setLoadingWhatsappTemplates,
                                                    // (chosenFund && config?.factsheetTemplates) ? config.factsheetTemplates[chosenFund?.type].whatsappFactsheetTemplate : undefined
                                                )}
                                                variant="subtle" aria-label="refres-templates">

                                                <IconRefresh stroke={1.5} />
                                            </ActionIcon>
                                        </Grid.Col>
                                    </Grid>


                                }
                            </Card>
                        </Grid.Col>
                        <Grid.Col span={{ xs: 6 }}>

                            <Card shadow="sm" padding="lg" radius="md" withBorder>
                                <Checkbox
                                    color="green"

                                    value="sms"
                                    // label="SMS"
                                    label={
                                        <Grid justify='center' align='center'>
                                            <Grid.Col span={12}>

                                                SMS
                                            </Grid.Col>


                                        </Grid>
                                    }
                                />
                                {

                                    form.values.type?.includes("sms") &&
                                    <Grid.Col span={{ xs: 12 }}>
                                        <Textarea
                                            label={"SMS Message"}
                                            value={form.values.smsMessage}
                                            onChange={(e) => {
                                                form.setFieldValue("smsMessage", e.target.value)
                                                MessageTextchanged(e.target.value, setSMSSampleText)
                                            }}
                                            minRows={4}
                                        />
                                        <Text fs="italic" c="dimmed" size="xs">
                                            You can use variables like {"{{name_en}}"} and {"{{name_ar}}"}
                                        </Text>
                                        <p>
                                            <Text size="xs">SAMPLE</Text>
                                        </p>
                                        <p>
                                            <Text c="blue" dir={form.getInputProps("language").value === "Arabic" ? "rtl" : "ltr"}>{smsSampleText}</Text>
                                        </p>

                                    </Grid.Col>
                                }
                            </Card>
                        </Grid.Col>
                        <Grid.Col span={{ xs: 6 }}>
                            <Card shadow="sm" padding="lg" radius="md" withBorder>
                                <Checkbox
                                    color="green"

                                    value="customEmail"
                                    // label="SMS"
                                    label={
                                        <Grid justify='center' align='center'>
                                            <Grid.Col span={12}>

                                                Customized Email
                                            </Grid.Col>


                                        </Grid>
                                    }
                                />
                                {form.values.type?.includes("customEmail") && <Grid.Col span={{ xs: 12 }} >
                                    <Text>
                                        Subject
                                    </Text>
                                    <Input
                                        required
                                        value={form.values.emailSubject}
                                        mt={10}
                                        onChange={(e) => {
                                            form.setFieldValue("emailSubject", e.target.value)

                                        }}
                                    />
                                    <Divider my="md" w="100%" />
                                    <Grid.Col span={{ xs: 12 }}>
                                        <Textarea
                                            label={"Email Message"}
                                            value={form.values.emailMessage}
                                            onChange={(e) => {
                                                form.setFieldValue("emailMessage", e.target.value)
                                                MessageTextchanged(e.target.value, setEmailSampleText)
                                            }}
                                            minRows={4}
                                        />
                                        <Text fs="italic" c="dimmed" size="xs">
                                            You can use variables like {"{{name_en}}"} and {"{{name_ar}}"}
                                        </Text>
                                        <p>
                                            <Text size="xs">SAMPLE</Text>
                                        </p>
                                        <p>
                                            <Text c="blue" dir={form.getInputProps("language").value === "Arabic" ? "rtl" : "ltr"}>{emailSampleText}</Text>
                                        </p>

                                    </Grid.Col>
                                </Grid.Col>}
                            </Card>

                        </Grid.Col>
                        <Grid.Col span={{ xs: 12 }}>
                            <Card shadow="sm" padding="lg" radius="md" withBorder>
                                <Checkbox
                                    color="green"

                                    value="notification"
                                    // label="SMS"
                                    label={
                                        <Grid justify='center' align='center'>
                                            <Grid.Col span={12}>

                                                Notification
                                            </Grid.Col>


                                        </Grid>
                                    }
                                />
                                {form.values.type?.includes("notification") && <Grid.Col >
                                    {/* <Text>
                                                    Title
                                                </Text> */}
                                    <Input
                                        required
                                        placeholder='Title'
                                        // mt={10}
                                        {...form.getInputProps('notificationTitle')}
                                        onChange={(e) => {
                                            form.setFieldValue("notificationTitle", e.target.value)

                                        }}
                                    />
                                    <Divider my="md" w="100%" />
                                    {/* <Grid.Col span={{ xs: 12 }}> */}
                                    <Textarea
                                        // label={"Notification Body"}
                                        placeholder='Body'
                                        required
                                        {...form.getInputProps('notificationBody')}

                                        onChange={(e) => {
                                            form.setFieldValue("notificationBody", e.target.value)
                                            MessageTextchanged(e.target.value, setNotificationSampleText)
                                        }}
                                        minRows={4}
                                    />
                                    <Text fs="italic" c="dimmed" size="xs">
                                        You can use variables like {"{{name_en}}"} and {"{{name_ar}}"}
                                    </Text>
                                    <p>
                                        <Text size="xs">SAMPLE</Text>
                                    </p>
                                    <p>
                                        <Text c="blue" dir={form.getInputProps("language").value === "Arabic" ? "rtl" : "ltr"}>{notificationSampleText}</Text>
                                    </p>

                                    {/* </Grid.Col> */}
                                    <Divider my="md" w="100%" />
                                    <Grid align='left'>
                                        <Grid.Col span={{ xs: 12 }} >
                                            <Text>Data</Text>
                                            <Select
                                                // label="Select data"
                                                placeholder='Select Data Type'
                                                value={form.values.notificationData?.type}
                                                {...form.getInputProps('notificationData.type')}

                                                onChange={(value, option: any) => {
                                                    // setSelectedNotificationDataType(value ?? "none");
                                                    // setSelectedWhatsappTemplate(option)
                                                    if (value === "none") {
                                                        form.setFieldValue("notificationData", undefined);
                                                    } else if (value === "kyc") {
                                                        form.setFieldValue("notificationData", {
                                                            type: "kyc",
                                                            value: ""
                                                        });

                                                    } else {
                                                        form.setFieldValue("notificationData", {
                                                            type: value === "link" ? "link" : "fund",
                                                            value: ""
                                                        });
                                                    }


                                                    // MessageTextchanged(option?.types["twilio/text"] ? option?.types["twilio/text"]?.body : option?.types["twilio/call-to-action"]?.body)
                                                }}
                                                data={[
                                                    {
                                                        value: "none",
                                                        label: "None",
                                                    },
                                                    {
                                                        value: "kyc",
                                                        label: "KYC",
                                                    },
                                                    {
                                                        value: "fund",
                                                        label: "Fund",
                                                    },
                                                    {
                                                        value: "link",
                                                        label: "Link",
                                                    },
                                                ]}
                                            />
                                        </Grid.Col>
                                        {/* <Grid.Col span={3} mt={25}>

                                                    <Select
                                                        label="Select data"
                                                        value={selectedNotificationDataValue}
                                                        onChange={(value, option: any) => {
                                                            setSelectedNotificationDataValue(value);
                                                            form.setFieldValue("notificationData.value", value)

                                                            // setSelectedWhatsappTemplate(option)
                                                            // form.setFieldValue("selectedWhatsappTemplate", option)

                                                            // MessageTextchanged(option?.types["twilio/text"] ? option?.types["twilio/text"]?.body : option?.types["twilio/call-to-action"]?.body)
                                                        }}
                                                        data={[
                                                            {
                                                                value: "MMF",
                                                                label: "Money Market",
                                                            },
                                                            {
                                                                value: "gcc",
                                                                label: "Gulf fund",
                                                            },
                                                        ]}
                                                    />
                                                </Grid.Col> */}
                                        <Grid.Col span={{ xs: 12 }}>
                                            {form.values?.notificationData?.type === "fund" &&
                                                <Select
                                                    // onChange={setFund}
                                                    //   disabled={pending}
                                                    // miw={400}
                                                    // maxDropdownHeight={400}
                                                    {...form.getInputProps('notificationData.value')}
                                                    // value={selectedFund?.id}
                                                    data={transformFundsToGroupedData(funds.filter((funcObj) => !!funcObj.value))}
                                                    placeholder="Choose fund type"
                                                    onChange={(value) => {
                                                        //get the fund object
                                                        let fund = funds.find((fund) => fund.id === value);
                                                        if (!fund) return;
                                                        form.setFieldValue("notificationData.value", fund.id)
                                                        setSelectedFund(fund as Fund);
                                                        // recalcAmount();
                                                    }}

                                                />
                                            }
                                            {form.values?.notificationData?.type === "link" &&
                                                <TextInput
                                                    required
                                                    placeholder='Link'
                                                    // mt={10}
                                                    {...form.getInputProps('notificationDataLink')}
                                                    value={form.values.notificationDataLink}
                                                    error={form.errors.notificationDataLink}
                                                    onChange={(e) => {
                                                        form.setFieldValue("notificationData.value", e.target.value)
                                                    }}
                                                />
                                            }
                                        </Grid.Col>
                                    </Grid>

                                </Grid.Col>}
                            </Card>

                        </Grid.Col>

                    </Grid>
                </Checkbox.Group>
            </Grid.Col>



            <Divider my="md" w="100%" />








        </Grid>
    )
}

export default BasicCampaignForm