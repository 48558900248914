import { useForm } from '@mantine/form';
import { BaseResult, ConfigDataType, CreateCampaignRequest, FundAutoComplete } from '../../types';
import { Button, Modal, Text } from '@mantine/core';
import BasicCampaignForm from '../../routes/campaigns/basic_campaign_form';
import { useDisclosure } from '@mantine/hooks';
import { useFunctions } from '../../helpers/firebaseContext';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { ConfigContext } from '../../contexts';


const CreateFundCampaignButton = ({ fund, variables }: { fund?: FundAutoComplete, variables?: Record<string, any> }) => {

    const [opened, { open, close }] = useDisclosure(false);
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
    const [errMsg, setErrMsg] = useState<string>();
    const config: ConfigDataType = useContext(ConfigContext);

    const functions = useFunctions();
    const navigate = useNavigate();



    const campaignForm = useForm<
        CreateCampaignRequest
    >({
        initialValues: {
            title: fund?.label,
            rmRecipients: [],
            segmentsRecipients: [],
            customerRecipients: [],
            tagsRecipients: fund?.id ? [fund?.id] : [],
            smsMessage: "",
            messageVariables: { ...variables, fundName: fund?.label } || {},
            type: [],
            updateLink: {
                updateType: "broadcast",
            },



        },

    });

    useEffect(() => {
        if (fund && config?.factsheetTemplates) {
            campaignForm.setFieldValue("type", ["email", "whatsapp"])

        }
    }, [fund])

    return (
        <>
            {fund ?
                <>
                    <Modal

                        size={"70%"}
                        opened={opened}


                        onClose={() => {
                            campaignForm.reset();
                            close()
                        }} title='Please Select Campaign Type'>
                        <form
                            onSubmit={campaignForm.onSubmit(async (values) => {
                                setErrMsg(undefined)

                                if (values.customerRecipients.length <= 0 && values.tagsRecipients.length <= 0) {
                                    setErrMsg("either customers or tags need to be added");
                                    return;
                                }
                                if (values.type.length <= 0) {
                                    setErrMsg("atleast one type must be selected");
                                    return;
                                }


                                setLoadingSubmit(true)
                                try {



                                    const createCampaign = httpsCallable<CreateCampaignRequest, BaseResult>(functions, "createCampaign");
                                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                    const response = await createCampaign(values)
                                    setLoadingSubmit(false);
                                    navigate("/campaigns");
                                    return;

                                    // if (response?.data?.messageID) {

                                    //     setSMSDocID([response?.data?.messageID]);
                                    //     setLoadingSubmit(false);


                                    // }
                                    // if (response?.data?.messageIDsList) {
                                    //     setSMSDocID(response?.data?.messageIDsList);
                                    //     setLoadingSubmit(false);

                                    // }


                                }
                                catch (e: any) {
                                    if (e?.message) {
                                        setErrMsg(e?.message)

                                    }
                                    else {
                                        setErrMsg("an unexpcted error has occured ")

                                    }

                                    setLoadingSubmit(false);

                                }

                            })}
                        >

                            <BasicCampaignForm form={campaignForm} funds={[fund]}


                            />

                            <Text c={"red"}>
                                {errMsg}
                            </Text>

                            <Button
                                loading={loadingSubmit}
                                type='submit'
                            >
                                Submit
                            </Button>
                        </form>
                    </Modal>
                    <Button
                        variant="light"
                        color="blue"
                        onClick={open}
                    >
                        Create Campaign

                    </Button>
                </>
                : <>


                    {/* //no fund ID ?? (not sure if possible but just in case) */}
                    <Text>Invalid Fund ID </Text>
                </>}
        </>
    )
}

export default CreateFundCampaignButton