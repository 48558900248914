import { FundID, OrdersPath, ServiceType, SubscriptionType } from "../types";


export const ordersPath: Record<SubscriptionType, Record<FundID, OrdersPath[]>> = {
    subscription: {
        "901": [{
            "path": "https://storage.googleapis.com/public-pdfs/MMF%D8%B7%D9%84%D8%A8%20%D8%A7%D9%84%D8%A7%D8%B4%D8%AA%D8%B1%D8%A7%D9%83.pdf",
            "doNotFlatten": false,
            "title": "MMF Subscribe",
        }],

        "902": [{
            "path": "https://storage.googleapis.com/public-pdfs/USD%20MMF%20-%20subscription%20form%20-%20final.pdf",
            "doNotFlatten": false,
            "title": "MMF USD Subscribe",
        }],

        "500": [{
            "path":
                "https://storage.googleapis.com/public-pdfs/Sukuk_%D8%B7%D9%84%D8%A8%20%D8%A7%D9%84%D8%A7%D8%B4%D8%AA%D8%B1%D8%A7%D9%83.pdf",
            "doNotFlatten": false,
            "title": "sukuk Subscribe",
        }],

        "201": [{
            "path":
                "https://storage.googleapis.com/public-pdfs/PremierMarketFund.pdf",
            "doNotFlatten": false,
            "title": "premier Subscribe",
        }],

        "200": [{
            "path":
                "https://storage.googleapis.com/public-pdfs/GCC%20equities%20%D8%B7%D9%84%D8%A8%20%D8%A7%D8%B4%D8%AA%D8%B1%D8%A7%D9%83.pdf",
            "doNotFlatten": false,
            "title": "gcc Subscribe",
        }],

        "usFund": [
            {
                "path": "https://storage.googleapis.com/public-pdfs/U.S-6-fund-checklist.pdf",
                "doNotFlatten": false,
                "title": "checklist",
            },
            {
                "path": "https://storage.googleapis.com/public-pdfs/kycind-update-closed-ended.pdf",
                "doNotFlatten": false,
                "title": "kyc",
            },
            {
                "path": "https://storage.googleapis.com/public-pdfs/KFHC%20US%20RE%20Fund%20VI%20-%20NonVoting%20PSA%20-%20English%20-%20april%202024.pdf",
                // "path": "https://storage.googleapis.com/public-pdfs/KFHC%20US%20RE%20Fund%20VI%20-%20Voting%20PSA%20-%20English%20april%202024.pdf",
                "doNotFlatten": false,
                "title": "US-re Subscribe",
                agreementType: "nonvoting",
            },
            {
                // "path": "https://storage.googleapis.com/public-pdfs/KFHC%20US%20RE%20Fund%20VI%20-%20NonVoting%20PSA%20-%20English%20-%20april%202024.pdf",
                "path": "https://storage.googleapis.com/public-pdfs/KFHC%20US%20RE%20Fund%20VI%20-%20Voting%20PSA%20-%20English%20april%202024.pdf",
                "doNotFlatten": false,
                "title": "US-re Subscribe",
                agreementType: "voting",
            },
            {
                "path": "https://storage.googleapis.com/public-pdfs/fatca-cayman.pdf",
                "doNotFlatten": false,
                "title": "fatca cayman",
            },
            {
                "path": "https://storage.googleapis.com/public-pdfs/Cayman-Form.pdf",
                "doNotFlatten": false,
                "title": "cayman self certification",
            },
        ],
        "XY3ENvdw0UEtjQgLeABZ": [
            {
                "path": "https://storage.googleapis.com/public-pdfs/IJARA%202%20fund%20checklist.pdf",
                "doNotFlatten": false,
                "title": "Ijara 2 checklist",
            },
            {
                "path": "https://storage.googleapis.com/public-pdfs/kycind-update-closed-ended.pdf",
                "doNotFlatten": false,
                "title": "kyc",
            },
            {
                "path": "https://storage.googleapis.com/public-pdfs/fatca-cayman.pdf",
                "doNotFlatten": false,
                "title": "fatca cayman",
            },
            {
                "path": "https://storage.googleapis.com/public-pdfs/ijara-2.pdf",
                "doNotFlatten": false,
                "title": "Ijara 2 Subscribe",
                "sourcesOfIncomeTransformers": {
                    "retired": "End of Service Indemnity",
                    "sourcesOfIncome_profit": "Investments in funds and stock markets",
                    "sourcesOfIncome_salary": "Savings over the years",
                    "sourcesOfIncome_inheritance": "Inheritance",
                    "sourcesOfIncome_other": "{{sourcesOfIncome_other_field}}"
                },
                "sourcesOfWealthTransformers": {
                    "retired": "End of Service Indemnity",
                    "sourcesOfWealth_profit": "Investments in funds and stock markets",
                    "sourcesOfWealth_inheritance": "Inheritance",
                    "sourcesOfWealth_other": "{{sourcesOfWealth_other_field}}"
                },
            },
            {
                "path": "https://storage.googleapis.com/public-pdfs/IJARA%20Fund%20II-KYC%20indiv%20Subs%20Agreement%20-%20Manual.pdf",
                "doNotFlatten": false,
                "title": "ijara 2 wakala",
            },
        ],

        "Pfn6bBMWKckyFcr2EKPJ": [

            {
                "path": "https://storage.googleapis.com/public-pdfs/UK-RE4-checklist-1.pdf",
                "doNotFlatten": false,
                "title": "checklist",
            },
            {
                "path": "https://storage.googleapis.com/public-pdfs/kycind-update-closed-ended.pdf",
                "doNotFlatten": false,
                "title": "kyc",
            },
            {
                "path": "https://storage.googleapis.com/public-pdfs/uk-re-with-fees.pdf",
                "doNotFlatten": false,
                "title": "uk-re Subscribe",
                "sourcesOfIncomeTransformers": {
                    "retired": "End of Service Indemnity",
                    "sourcesOfIncome_profit": "Investments in funds and stock markets",
                    "sourcesOfIncome_salary": "Savings over the years",
                    "sourcesOfIncome_inheritance": "Inheritance",
                    "sourcesOfIncome_other": "{{sourcesOfIncome_other_field}}"
                },
                "sourcesOfWealthTransformers": {
                    "retired": "End of Service Indemnity",
                    "sourcesOfWealth_profit": "Investments in funds and stock markets",
                    "sourcesOfWealth_inheritance": "Inheritance",
                    "sourcesOfWealth_other": "{{sourcesOfWealth_other_field}}"
                },
                "addFields": {
                    "witness": "I have witnessed the signature of the applicant on {{paciData.ResultDetails.TransactionDate}}"
                },


            },
            {
                "path": "https://storage.googleapis.com/public-pdfs/wakala-fillable-form.pdf",
                "doNotFlatten": false,
                "title": "uk-re wakala",
            }
        ],
        "3006": [

            {
                "path": "https://storage.googleapis.com/public-pdfs/KFHC%20UK%205%20-%20Fund%20Checklist.pdf",
                "doNotFlatten": false,
                "title": "checklist",
            },
            {
                "path": "https://storage.googleapis.com/public-pdfs/kycind-update-closed-ended.pdf",
                "doNotFlatten": false,
                "title": "kyc",
            },
            {
                "path": "https://storage.googleapis.com/public-pdfs/KFHCapital-UK%20V%20Subscription%20Agreement%20(Fillable).pdf",
                "doNotFlatten": false,
                "title": "uk-re 5 Subscribe",
                "sourcesOfIncomeTransformers": {
                    "retired": "End of Service Indemnity",
                    "sourcesOfIncome_profit": "Investments in funds and stock markets",
                    "sourcesOfIncome_salary": "Savings over the years",
                    "sourcesOfIncome_inheritance": "Inheritance",
                    "sourcesOfIncome_other": "{{sourcesOfIncome_other_field}}"
                },
                "sourcesOfWealthTransformers": {
                    "retired": "End of Service Indemnity",
                    "sourcesOfWealth_profit": "Investments in funds and stock markets",
                    "sourcesOfWealth_inheritance": "Inheritance",
                    "sourcesOfWealth_other": "{{sourcesOfWealth_other_field}}"
                },
                "addFields": {
                    "witness": "I have witnessed the signature of the applicant on {{paciData.ResultDetails.TransactionDate}}"
                },


            },
            {
                "path": "https://storage.googleapis.com/public-pdfs/wakala-fillable-form.pdf",
                "doNotFlatten": false,
                "title": "uk-re wakala",
            }
        ]
    }
    ,
    redemption: {
        "901": [{
            "path": "https://storage.googleapis.com/public-pdfs/MMF%20%D8%B7%D9%84%D8%A8%20%D8%A7%D9%84%D8%A7%D8%B3%D8%AA%D8%B1%D8%AF%D8%A7%D8%AF.pdf",
            "doNotFlatten": false,
            "title": "MMF Redeem",
        }],
        "902": [{
            "path": "https://storage.googleapis.com/public-pdfs/USD%20MMF%20-%20redemption%20form%20-%20final.pdf",
            "doNotFlatten": false,
            "title": "MMF USD Redeem",
        }],
        "500": [{
            "path": "https://storage.googleapis.com/public-pdfs/Sukuk%20%D8%B7%D9%84%D8%A8%20%D8%A7%D8%B3%D8%AA%D8%B1%D8%AF%D8%A7%D8%AF.pdf",
            "doNotFlatten": false,
            "title": "sukuk Redeem",
        }],
        "201": [{
            "path": "https://storage.googleapis.com/public-pdfs/PremierMarketFund_14052023.pdf",
            "doNotFlatten": false,
            "title": "premier Redeem",
        }],
        "200": [{
            "path": "https://storage.googleapis.com/public-pdfs/GCC%20equities%20%D8%B7%D9%84%D8%A8%20%D8%A7%D8%B3%D8%AA%D8%B1%D8%AF%D8%A7%D8%AF.pdf",
            "doNotFlatten": false,
            "title": "gcc Redeem",
        }],
        "Pfn6bBMWKckyFcr2EKPJ": [{
            "path": "https://storage.googleapis.com/public-pdfs/uk-re-with-fees.pdf",
            "doNotFlatten": false,
            "title": "uk-re Subscribe",
        }],
        usFund: [],
        XY3ENvdw0UEtjQgLeABZ: [],
        "3006": []
    }

}


export const servicePaths: Record<
    ServiceType, OrdersPath[]
> = {
    kfhtrade: [{
        path: "https://storage.googleapis.com/public-pdfs/KFH-TRADE.pdf",
        doNotFlatten: false,
        title: "KFH TRADE",
    }],
    portfolio: [


    ],
    kfhbrokerage: [
        {
            path: "https://storage.googleapis.com/public-pdfs/KFH_BROKERAGE_FORM_FINAL.pdf",
            doNotFlatten: false,
            title: "KFH BROKERAGE",
        }

    ]
}